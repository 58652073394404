import { red } from "@material-ui/core/colors";
import React, { useState, useEffect } from "react";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';


const CreateCaseModel = (props) => {
    let opportunityList = [
        {
            name: "Select Opportunity",
            value: "select opportunity",
            key: 0
        },
        {
            name: "Install",
            value: "install",
            key: 1
        },
        {
            name: "Troubleshoot",
            value: "troubleshoot",
            key: 2
        }
    ];

    const [opportunityTypes, setopportunityTypes] = useState(opportunityList);
    const [isOpportunity, setIsOpportunity] = useState(false);
    const [selectedOpportunity, setSelectedOpportunity] = useState(opportunityTypes[0]);

    const handleChange = (e) => {
        e.preventDefault();
        setSelectedOpportunity(opportunityTypes[e.target.selectedIndex]);
    };

    const onCreateCase = (e) => {
        e.preventDefault();

        let selectoppvalue = opportunityList.find(x => x.name == selectedOpportunity.name);

        if (selectoppvalue.key != 0) {
            setIsOpportunity(false);
            props.createCase(selectoppvalue);
        }
        else {
            setIsOpportunity(true);
        }
    };

    const onCancelClick = (e) => {
        props.closeCreateCaseModle();
    };

    return (
        <div className="modal" style={{ display: true ? "block" : "none" }}>
            <div className="modal-wrapper"
                style={{
                    transform: true ? 'translateY(0vh)' : 'translateY(-100vh)',
                    opacity: true ? '1' : '0'
                }}>

                <div className="modal-header">
                    <h3>Create Job</h3>
                    <span className="close-modal-btn" onClick={e => { onCancelClick(); }}>×</span>
                </div>

                <div className="modal-body-2 h-300" style={{ textAlign: "left" }}>
                    <form>
                        <div className="row">
                            <div className="col-sm-12">
                                <div style={{ paddingRight: 2 + "px" }}>
                                    <label htmlFor="description" style={{ textAlign: "right", height: 40 + "px", width: 40 + "%", paddingRight: 40 + "Px" }} >Opportunity Types :</label>
                                    <select name={selectedOpportunity.name} value={selectedOpportunity.value} onChange={e => handleChange(e)} style={{ textAlign: "left", height: 40 + "px", width: 35 + "%", paddingLeft: 10 + "Px" }} >
                                        {opportunityTypes?.map(o => (
                                            <option key={o.key} value={o.value}>{o.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <br />
                            </div>
                        </div>
                        {isOpportunity && <label htmlFor="description" style={{ textAlign: "center", height: 30 + "px", width: 100 + "%", paddingLeft: 0 + "Px", color: "red" }} >Select opportunity type</label>}
                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { onCancelClick(); }}>Cancel</button>
                            <button className="primary-btn btn-small" onClick={e => onCreateCase(e)}>Create Case</button>
                        </div>
                    </form>
                    <hr />
                </div>
            </div>
        </div>
    );
};

export default CreateCaseModel;