import React from "react";

function RequestPayloadModal({ show, requestPayload, onClick }) { 
    
    const formatRequestPayload = () => {
        let displayString = "The request payload will be available when the install is completed.";
        
        if (requestPayload) {
            let jsonData = JSON.parse(requestPayload);
            displayString = JSON.stringify(jsonData, null, 2);
        }
        
        return displayString;
    }
    
    return (
        <div className="modal" style={{display: show ? "block" : "none"}}>
            <div className="modal-wrapper"
                 style={{
                     transform: show ? 'translateY(0vh)' : 'translateY(-100vh)',
                     opacity: show ? '1' : '0'
                 }}>

                <div className="modal-header">
                    <h3>Request Payload</h3>
                    <span className="close-modal-btn" onClick={() => onClick()}>×</span>
                </div>

                <div style={{margin: "10px"}}>
                    <pre>
                        <code>
                            {formatRequestPayload()}
                        </code>
                    </pre>
                </div>
                
            </div>
        </div>
    );
}

export default RequestPayloadModal;
