/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import AccountService from "../../services/account.service";
import PartnerService from "../../services/partner.service";
import RegisterUserService from "../../services/registeruser.service";
import MailjetService from '../../services/mailjet.service';
import SelectPartnerDataModal from "./SelectPartnerDataModal";
import InviteFamilyMemberModal from "./InviteFamilyMemberModal";
import InviteUserModal from "../AccountManagement/Users/InviteUserModal";
import EmailStatusTable from '../Tools/EmailStatusTable'

import memoize from "memoize-one";
import { withStyles } from '@material-ui/core/styles';

import { Event, PageView, ModalView, Timing } from "../GoogleAnalytics";
import { jsAccount, jsCreateCase } from '../../componentObjects';
import ForgotPasswordModal from './ForgotPasswordModal';
import AssetService from '../../services/asset.service';
import EmailLinksService from '../../services/emailLinks.service';
import Tooltip from '@material-ui/core/Tooltip';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import ReinviteHomeownerModal from './ReinviteHomeownerModal';
import EmailLinkModal from './EmailLinkModal';
import ConfirmDeleteAccountModal from "./ConfirmDeleteAccountModal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import ConfirmPasswordModal from "./ConfirmUser/ConfirmPasswordModal";
import UserAssetList from "../Assets/Hierarchy/UserAssetList";
import PhoneNumberInput from "../Phone/PhoneNumberInput";
import CreateCaseModel from "../AccountManagement/Account/CreateCaseForm";
import Snackbar from '@material-ui/core/Snackbar';

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

const reparentMessage = "Updating Parent Organization. This Can Take Up to 30 Seconds.";
const successMessage = "The account was updated successfully!";
const failureMessage = "Failed to update the account!";
const loadingMessage = "Loading Customer Account";
const createcasesuccessMessage = "case was created successfully!"
const createcasefailureMessage = "Failed to create case!";


class Account extends Component {
    static displayName = Account.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.getAccount = this.getAccount.bind(this);
        this.updateAccount = this.updateAccount.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.onChangeCustomerRecordId = this.onChangeCustomerRecordId.bind(this);

        this.showPartnerModal = this.showPartnerModal.bind(this);
        this.showInviteModal = this.showInviteModal.bind(this);
        this.openInviteFamilyMember = this.openInviteFamilyMember.bind(this);
        this.showResetPasswordModal = this.showResetPasswordModal.bind(this);
        this.showReinviteHomeownerModal = this.showReinviteHomeownerModal.bind(this);
        this.openActivationLinkModal = this.openActivationLinkModal.bind(this);
        this.showActivationLinkModal = this.showActivationLinkModal.bind(this);
        this.onReinviteEmailChange = this.onReinviteEmailChange.bind(this);
        this.onReinviteHomeowner = this.onReinviteHomeowner.bind(this);
        this.onTryReinviteAgain = this.onTryReinviteAgain.bind(this);
        this.onChangeParent = this.onChangeParent.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSaveFamilyMember = this.onSaveFamilyMember.bind(this);
        this.onInviteEmailChange = this.onInviteEmailChange.bind(this);
        this.setPartnerCallback = this.setPartnerCallback.bind(this);
        this.showDeleteAccounModal = this.showDeleteAccounModal.bind(this);
        this.cancelAccountDelete = this.cancelAccountDelete.bind(this);

        this.onContinueWithoutSaving = this.onContinueWithoutSaving.bind(this);
        this.onSaveThenContinue = this.onSaveThenContinue.bind(this);

        this.onInvite = this.onInvite.bind(this);
        this.openInviteUserModal = this.openInviteUserModal.bind(this);
        this.showInviteUserModal = this.showInviteUserModal.bind(this);

        this.showPasswordConfirmationModal = this.showPasswordConfirmationModal.bind(this);
        this.checkAccountAssets = this.checkAccountAssets.bind(this);
        this.showConfirmationModal = this.showConfirmationModal.bind(this);
        this.handleError = this.handleError.bind(this);
        this.showCreateCasedModal = this.showCreateCasedModal.bind(this);
        this.createCaseAsync = this.createCaseAsync.bind(this);


        this.state = {
            partnerList: [],
            currentAccount: localStorage.getItem("customerAccount") || jsAccount,
            currentUser: {},
            showPartner: false,
            showInvite: false,
            showDeleteAccount: false,
            showResetPassword: false,
            showReinviteHomeowner: false,
            showActivationLink: false,
            reinviteLoading: false,
            reinviteFromUser: false,
            reinviteEmail: "",
            reinviteSent: false,
            reinviteResult: false,
            showInviteConfirm: false,
            inviteLoading: false,
            message: "",
            famMessage: "",
            emailToReset: "",
            loading: false,
            tableLoading: false,
            isAdmin: false,
            isDeleted: false,
            isActiveBillingPlan: false,
            waitDelete: false,
            showPasswordConfirmation: false,
            needConfirmation: false,
            showConfirmation: false,
            modalTitle: "",
            userassethierarchy: [],
            messages: [],
            isCustomerSupport: localStorage.getItem('isCustomerSupport') === 'true' || false,
            currentLinkObj: null,
            unsavedChanges: localStorage.getItem("unsavedChanges") === 'true' || false,
            showUnsavedModal: null,
            error: "",
            isUpdating: false,
            showCreateCase: false,
            createCaseObj: jsCreateCase,
            message: "",
            showMessage: false,
        };
    }

    getStorageValue(key, defaultValue) {
        const saved = localStorage.getItem(key);
        const value = saved !== null ? saved : defaultValue;
        return value;
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        let unsavedChanges = this.getStorageValue("unsavedChanges", false);
        if (!this.props.isLoading) {
            if (unsavedChanges === 'true' || unsavedChanges === true) {
                let account = this.getStorageValue("customerAccount", jsAccount);
                if (account !== jsAccount)
                    account = JSON.parse(account);
                this.setState({
                    unsavedChanges: unsavedChanges,
                    currentAccount: account
                });
            }
            else
                this.getAccount();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    memAccount = memoize(this.getAccount);
    handleError(error) {
        this.setState({ error });
    }
    onChangeFirstName(e) {
        const firstName = e.target.value;

        this.setState(function (prevState) {
            return {
                unsavedChanges: true,
                currentAccount: {
                    ...prevState.currentAccount,
                    firstName: firstName
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("customerAccount", JSON.stringify(this.state.currentAccount));
        });
    }

    onChangeLastName(e) {
        const lastName = e.target.value;

        this.setState(prevState => ({
            unsavedChanges: true,
            currentAccount: {
                ...prevState.currentAccount,
                lastName: lastName
            }
        }), () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("customerAccount", JSON.stringify(this.state.currentAccount));
        });
    }

    onChangeEmail(e) {
        const email = e.target.value;

        this.setState(prevState => ({
            unsavedChanges: true,
            currentAccount: {
                ...prevState.currentAccount,
                email: email
            }
        }), () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("customerAccount", JSON.stringify(this.state.currentAccount));
        });
    }

    onChangePhone(e) {
        const phone = e;
        this.setState(prevState => ({
            unsavedChanges: true,
            currentAccount: {
                ...prevState.currentAccount,
                phone: phone
            }
        }), () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("customerAccount", JSON.stringify(this.state.currentAccount));
        });
    }

    onChangeCustomerRecordId(e) {
        const regex = /^\d+$/;
        const customerRecordId = e.target.value;

        if ((regex.test(customerRecordId) || customerRecordId === "") && customerRecordId.length <= 7) {
            this.setState(prevState => ({
                unsavedChanges: true,
                currentAccount: {
                    ...prevState.currentAccount,
                    customerRecordId: customerRecordId
                }
            }), () => {
                localStorage.setItem("unsavedChanges", true);
                localStorage.setItem("customerAccount", JSON.stringify(this.state.currentAccount));
            });
        } else if (this.state.currentAccount.customerRecordId == null) {
            this.setState(prevState => ({
                unsavedChanges: true,
                currentAccount: {
                    ...prevState.currentAccount,
                    customerRecordId: ""
                }
            }), () => {
                localStorage.setItem("unsavedChanges", true);
                localStorage.setItem("customerAccount", JSON.stringify(this.state.currentAccount));
            });

        }


    }

    onChangeParent(e) {
        const newParentId = e.target.value;

        this.setState(prevState => ({
            unsavedChanges: true,
            currentAccount: {
                ...prevState.currentAccount,
                parentAccountId: newParentId
            }
        }), () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("customerAccount", JSON.stringify(this.state.currentAccount));
        });
    }

    onSave() {
        Event("Save Clicked", "User clicked save button in Customer Account", "Save Clicked");
        this.props.setReparent(reparentMessage);
        this.updateAccount(true);
        this.setState({
            showPartner: !this.state.showPartner
        });

    }

    onContinueWithoutSaving(continueFromAppJs = false) {
        if (this._isMounted)
            this.setState({ loading: false });
        localStorage.setItem("unsavedChanges", false);
        localStorage.setItem("showUnsavedModal", false);
        localStorage.setItem("customerAccount", JSON.stringify(jsAccount));

        if (continueFromAppJs)
            this.props.onContinueFromAccount(this.state.currentAccount.parentAccountId, this.state.currentAccount.accountId, successMessage, this.setPartnerCallback);
        else {
            let activePath = this.getStorageValue("activePath", "/account");
            let nextPath = this.getStorageValue("nextPath", null);

            this.props.onContinueFromAccount(null, null, "", this.setPartnerCallback);
        }
    }

    onSaveThenContinue() {
        this.setState({ showUnsavedModal: false }, () => {
            localStorage.setItem("showUnsavedModal", false);
            let account = this.getStorageValue("customerAccount", jsAccount);
            if (account !== jsAccount)
                account = JSON.parse(account);

            this.setState({ currentAccount: account, loading: true }, () => {
                //this.updatePartner(true);
                this.props.setReparent(reparentMessage);
                this.updateAccount(false, true);
            });
        })
    }

    getMessages(contactId, isLast) {
        if (this._isMounted) {
            MailjetService.getallcontactmessages(contactId)
                .then(response2 => {
                    if (this._isMounted && response2.count > 0 && response2.data !== null && response2.data !== undefined && response2.data.length) {
                        let updateMessages = this.state.messages.concat(response2.data);
                        if (isLast) {
                            this.setState({ loading: false, tableLoading: false, messages: updateMessages });
                        }
                        else {
                            this.setState({ messages: updateMessages });
                        }
                    }
                    else if (isLast){
                        this.setState({ loading: false, tableLoading: false });
                    }
                })
                .catch(e => {
                    console.log(e);
                    if (isLast){
                        this.setState({ loading: false, tableLoading: false });
                    }
                });
        }
    }

    getAccountEmails(familyMembers) {
        MailjetService.getsenders()
            .then(response3 => {
                if (this._isMounted)
                    this.setState({ senders: response3.data }, () => {
                        let isLast = false;
                        familyMembers.forEach((member) => {
                            if (familyMembers[familyMembers.length - 1] === member) {
                                isLast = true;
                            }
                            MailjetService.getcontact(member.email)
                                .then(response1 => {
                                    if (this._isMounted) {
                                        if (response1.count > 0 && response1.data !== null && response1.data !== undefined && response1.data.length) {
                                            var contactId = response1.data[0].id;
                                            this.setState({ contactId: contactId }, () => {
                                                this.getMessages(contactId, isLast);
                                                //this.maintainMessageSorting();
                                            });
                                        }
                                        else {
                                            if (isLast) {
                                                this.setState({ tableLoading: false, loading: false });
                                            }
                                            console.log("no email found");
                                        }
                                    }
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                        })
                    });
            })
            .catch(e => {
                console.log(e);
            });
    }

    getIsAdmin(roles) {
        if (roles != null && roles != undefined) {
            let isAdmin = false;
            if (typeof roles != 'string') {
                isAdmin = roles?.find(x => x == "SUPER ADMIN") || roles?.find(x => x == "ADMIN");
            }
            else {
                let userRoles = JSON.parse(roles);
                isAdmin = userRoles?.find(x => x == "SUPER ADMIN") || userRoles?.find(x => x == "ADMIN");
            }
            this.setState({ isAdmin: isAdmin });
        }
    }

    getAccount() {
        if (!this.props.isLoading) {
            this.setState({ loading: true }, async () => {
                var startTime = performance.now();
                await AccountService.get(this.props.currentCustomerId)
                    .then(response => {
                        if (this._isMounted) {
                            this.getIsAdmin(this.props?.roles);

                            let familyMembers = response.data.familyMembers;
                            if (familyMembers !== undefined && familyMembers.length > 0) {
                                this.getAccountEmails(familyMembers);
                            }
                            else {
                                this.setState({ loading: false, tableLoading: false });
                            }
                            this.setState({
                                currentAccount: response.data
                            }, () => {
                                var elapsedTime = performance.now() - startTime;
                                Timing("Account Loading", "loading", elapsedTime, "Customer Account Loading");
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({
                            loading: false
                        });
                    });
            })
        }
    }

    async getPartners() {
        let partnerObj = {
            organization: this.props.loggedInCustomerOrganization,
            isPartnerAdmin: this.props.isPartnerAdmin,
            isActive: true
        };
        await PartnerService.get(partnerObj).then(async response => {
            if (this._isMounted)
                this.setState({ partnerList: response.table });
        }).catch(e => {
            console.log(e)
        });
    }
    updateAccount(isReparent, goNextPage = false) {
        //Event("Customer Account Update", "User clicked update button in customer account page", "Update Customer Account Clicked");
        if (!this.state.currentAccount.phone || this.state.error) return;
        this.setState({ isUpdating: true });
        AccountService.update(
            this.state.currentAccount
        )
            .then(response => {
                if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                    if (isReparent === true) {
                        AssetService.updatehomesparent(this.state.currentAccount.accountId, this.state.currentAccount.parentAccountId)
                            .then(response2 => {
                                if (response2.status === 200) {

                                    localStorage.setItem("customerAccount", JSON.stringify(this.state.currentAccount));
                                    localStorage.setItem("unsavedChanges", false);
                                    localStorage.setItem("showUnsavedModal", false);
                                    if (goNextPage)
                                        this.onContinueWithoutSaving(true);
                                    else {
                                        this.props.setCurrentPartnerAndCustomer(this.state.currentAccount.parentAccountId, this.state.currentAccount.accountId, this.setPartnerCallback);
                                        this.props.setReparent(successMessage);
                                    }
                                    //this.props.setCurrentPartnerAndCustomer(this.state.currentAccount.parentAccountId, this.state.currentAccount.accountId, this.setPartnerCallback);
                                    //this.props.setReparent(successMessage);
                                }
                                else {
                                    this.props.setReparent(failureMessage);

                                    this.setState({
                                        message: failureMessage
                                    });
                                }
                            })
                    }
                    else {
                        localStorage.setItem("customerAccount", JSON.stringify(this.state.currentAccount));
                        localStorage.setItem("unsavedChanges", false);
                        localStorage.setItem("showUnsavedModal", false);
                        if (goNextPage)
                            this.onContinueWithoutSaving();
                    }
                    if (this._isMounted) {
                        this.setState({ message: successMessage }, () => {
                            localStorage.setItem("customerAccount", JSON.stringify(this.state.currentAccount));
                            localStorage.setItem("unsavedChanges", false);
                            localStorage.setItem("showUnsavedModal", false);
                            if (goNextPage)
                                this.onContinueWithoutSaving();
                        });
                    }

                    Event("Customer Update", "User updated customer account", "Successful Customer Account Update");
                }
                else {

                    if (isReparent === true)
                        this.props.setReparent(failureMessage);

                    this.setState({
                        message: failureMessage
                    });
                }
                this.setState({ isUpdating: false });
                setTimeout(() => {
                    var finalMessage = "";
                    if (isReparent === true) {
                        this.props.setReparent(loadingMessage);
                        finalMessage = loadingMessage;
                    }

                    if (this._isMounted)
                        this.setState({ message: finalMessage });
                }, 3000);

            })
            .catch(e => {
                console.log(e);
                this.setState({ isUpdating: false });
            });
    }

    checkAccountAssets(id) {
        this.setState({
            waitDelete: true
        }, () => {
            AssetService.getassethierarchy(id).then(response => {
                if (response.status === 200 && response.data !== undefined && response.data !== null && response.data.length > 0) {
                    this.setState({
                        userassethierarchy: response.data,
                        isDeleted: false,
                        isActiveBillingPlan: false,

                    });
                } else {
                    this.setState({
                        message: "Failed to delete the account! Reason: " + response.data,
                        isDeleted: false,
                        isActiveBillingPlan: false,

                    });
                }
                setTimeout(() => {
                    this.setState({
                        waitDelete: false
                    });
                }, 3000);

            })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    deleteAccount(id) {
        this.setState({
            waitDelete: true
        }, () => {
            AccountService.softDeleteAccount(this.props.currentPartnerId, this.props.currentCustomerId)
                .then(response => {
                    if (response.status === 200 && response.data !== undefined && response.data !== null && response.data === "Success") {
                        this.setState({
                            message: "The account was deleted successfully!",
                            isDeleted: true,
                            isActiveBillingPlan: false
                        });
                        Event("Customer Delete", "User deleted customer account", "Successful Customer Account Deletion");
                    } else if (response.status === 200 && response.data !== undefined && response.data !== null && response.data === "Active") {
                        this.setState({
                            message: "Need to cancel the subscription first before delete the account!",
                            isDeleted: false,
                            isActiveBillingPlan: true
                        });
                    } else {
                        this.setState({
                            message: "Failed to delete the account! Reason: " + response.data,
                            isDeleted: false,
                            isActiveBillingPlan: false
                        });
                    }
                    setTimeout(() => {
                        this.setState({
                            waitDelete: false
                        });
                    }, 3000);
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    deleteUser(userId) {
        let accountId = this.state.currentAccount.accountId;
        AccountService.deleteuser(accountId, userId)
            .then(response => {
                if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                    let members = this.state.currentAccount.familyMembers.filter(member => member.userId !== userId);
                    this.setState(prevState => ({
                        currentAccount: {
                            ...prevState.currentAccount,
                            familyMembers: members
                        },
                        message: "The account was deleted successfully!"
                    }));
                    Event("Customer Delete", "User deleted customer account", "Successful Customer Account Deletion");
                }
                else {
                    this.setState({
                        message: "An unexpected error occured. Please try a second time. If it fails a second time, please contact support"
                    });
                }
                setTimeout(() => {
                    this.setState({
                        message: ""
                    });
                }, 3000);
            })
            .catch(e => {
                console.log(e);
            });
    }

    cancelAccountDelete() {
        Event("Cancel Account Delete", "User canceled account delete", "Cancel Account Delete");
        this.setState({
            showCancelModal: false,
            showConfirmCancelModal: false,
            showConfirmation: false
        });
    }
    showConfirmationModal = e => {
        this.setState({
            showConfirmation: !this.state.showConfirmation,
            modalTitle: !this.state.showConfirmation ? "Delete Assets" : ""
        }, () => {
            if (this.state.showConfirmation) {
                this.checkAccountAssets(this.state.currentAccount.accountId);
                ModalView("../ConfirmationModal/ConfirmationModal");
            }
        });
        window.scrollTo(0, 0);
    }

    showPasswordConfirmationModal = () => {
        this.setState({
            showPasswordConfirmation: !this.state.showPasswordConfirmation,
            showCancelModal: false,
            showConfirmCancelModal: false,
            showConfirmation: false
        }, () => {
            if (this.state.showPasswordConfirmation) {
                ModalView("ConfirmPasswordModal");
            }
        });

        window.scrollTo(0, 0);
    }

    showDeleteAccounModal = e => {
        this.setState({
            showDeleteAccount: !this.state.showDeleteAccount,
            message: !this.state.showDeleteAccount ? "Would you like to delete the account?" : "",
            showPasswordConfirmation: false,
            showCancelModal: false,
            showConfirmCancelModal: false,
            showConfirmation: false

        }, () => {
            if (this.state.showDeleteAccount) {
                ModalView("/confirmDeleteAccount");
            } else if (this.state.isDeleted) {
                this.props.setCurrentPartnerAndCustomer(this.state.currentAccount.parentAccountId, 0, this.setPartnerCallback);
            }

        });
        window.scrollTo(0, 0);
    }
    showPartnerModal = e => {
        this.setState({
            showPartner: !this.state.showPartner
        }, () => {
            if (this.state.showPartner)
                ModalView("/reparentCustomer");
        });
        window.scrollTo(0, 0);
    }

    openPartnerModal() {
        this.getPartners();
        this.showPartnerModal(null);
    }

    showInviteModal = e => {
        this.setState({
            showInvite: !this.state.showInvite
        }, () => {
            if (this.state.showInvite)
                ModalView("/customerInvite");
        });
        window.scrollTo(0, 0);
    }

    openInviteUserModal(user) {
        this.showInviteUserModal();
    }

    showInviteUserModal() {
        this.setState({
            showInviteConfirm: !this.state.showInviteConfirm
        }, () => {
            if (this.state.showInviteConfirm)
                ModalView("inviteUser");
        });
        window.scrollTo(0, 0);
    }

    openInviteFamilyMember() {
        this.showInviteModal(null);
    }

    showResetPasswordModal = (emailAddress) => {
        let emailToReset = "";
        if (!this.state.showResetPassword) {
            emailToReset = emailAddress;
        }

        this.setState({
            showResetPassword: !this.state.showResetPassword, emailToReset: emailToReset
        }, () => {
            if (this.state.showResetPassword)
                ModalView("/resetPassword");
        });
        window.scrollTo(0, 0);
    }

    showCreateCasedModal = (user) => {
        let caseObj = jsCreateCase
        if (user != "undefined" && user != null) {
            caseObj = this.state.createCaseObj;
            caseObj.organizationId = this.state.currentAccount.accountId
            caseObj.userID = user.userId;
            caseObj.firstName = this.state.currentAccount.firstName;
            caseObj.lastName = this.state.currentAccount.lastName;
        }

        this.setState({
            showCreateCase: !this.state.showCreateCase,
            createCaseObj: caseObj
        });

        window.scrollTo(0, 0);
    }
    createCaseAsync(caseType) {
        let createCase = this.state.createCaseObj;
        createCase.comment = caseType.value;
        this.setState({
            loading: true
        }, async () => {
            await RegisterUserService.createCase(createCase)
                .then(response => {
                    if (response.status === 200) {
                        this.setState({
                            loading: false,
                            showCreateCase: false,
                            message: createcasesuccessMessage,
                            showMessage: true
                        });
                        setTimeout(() => {
                            this.setState({
                                message: "",
                                showMessage: false
                            });
                        }, 3000);
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.setState({
                        loading: false,
                        showCreateCase: false,
                        message: createcasefailureMessage,
                        showMessage: true
                    });
                    setTimeout(() => {
                        this.setState({
                            message: "",
                            showMessage: false
                        });
                    }, 3000);
                })
        });
    }
    openReinviteHomeownerModal(user) {
        this.setState({
            currentUser: user,
            reinviteFromUser: user == null ? false : true,
            reinviteEmail: user == null ? "" : user.email,
            reinviteSent: false, reinviteResult: false, reinviteLoading: false
        }, () => {
            this.showReinviteHomeownerModal();
        });
    }

    showReinviteHomeownerModal = () => {
        this.setState({
            showReinviteHomeowner: !this.state.showReinviteHomeowner
        }, () => {
            if (this.state.showReinviteHomeowner)
                ModalView("/reinviteHomeowner");
        });
        window.scrollTo(0, 0);
    }

    openActivationLinkModal(user) {
        this.setState({
            currentUser: user,
            reinviteFromUser: user == null ? false : true,
            reinviteEmail: user == null ? "" : user.email,
            reinviteSent: false, reinviteResult: false, reinviteLoading: false
            //todo add loading
        }, async () => {
            await EmailLinksService.get(this.props.currentCustomerId)
                .then(response => {
                    this.setState({
                        currentLinkObj: response,
                        showActivationLink: true
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.setState({
                        currentLinkObj: null,
                        showActivationLink: true
                    });
                })
        });
    }

    showActivationLinkModal = () => {
        this.setState({
            showActivationLink: !this.state.showActivationLink
        }, () => {
            if (this.state.showActivationLink)
                ModalView("/activationLink");
        });
    }

    onReinviteEmailChange(e) {
        const email = e.target.value;

        this.setState({ reinviteEmail: email });
    }

    onReinviteHomeowner() {
        if (!this.state.reinviteFromUser) {
            console.log("Reinviting without user");
            this.setState({ reinviteLoading: true, reinviteSent: true }, async () => {
                let account = {
                    email: this.state.reinviteEmail,
                    organizationId: this.props.currentCustomerId,
                    parentOrgName: this.props.currentPartnerId, //this.props.loggedInCustomerOrganization,
                    parentOrgId: this.state.currentAccount.parentAccountId,
                    firstName: this.state.currentAccount.firstName,
                    lastName: this.state.currentAccount.lastName,
                    latitude: null,
                    longitude: null
                };
                await RegisterUserService.emailhomeowner(account)
                    .then(response => {
                        let msg = "";
                        let reinviteSuccess = false;
                        if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                            msg = "The user was re-invited successfully!";
                            reinviteSuccess = true;
                            Event("User Re-Invite", "User was re-invited", "Successful User Re-Invite");
                        }
                        else {
                            msg = "Failed to re-invite the user!";
                        }

                        this.setState({
                            loading: false,
                            reinviteLoading: false,
                            reinviteResult: reinviteSuccess,
                            message: msg
                        });

                        setTimeout(() => {
                            this.setState({
                                message: ""
                            });
                        }, 3000);
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({
                            loading: false,
                            reinviteLoading: false,
                            reinviteResult: false,
                            message: "Failed to Re-Invite the user"
                        });

                        setTimeout(() => {
                            this.setState({
                                message: ""
                            });
                        }, 3000);
                    })
            });
        }
        else {
            console.log("Reinviting from user");
            this.setState({ loading: true, showReinviteHomeowner: false }, async () => {
                let userAccount = {
                    userId: this.state.currentUser.userId,
                    organizationId: this.props.currentCustomerId,
                    email: this.state.currentUser.email,
                    firstName: this.state.currentAccount.firstName,
                    lastName: this.state.currentAccount.lastName,
                    parentOrgName: this.props.loggedInCustomerOrganization,
                    parentOrgId: this.state.currentAccount.parentAccountId,
                    accountJson: null,
                    latitude: null,
                    longitude: null
                };
                await RegisterUserService.emailhomeowner(userAccount)
                    .then(response => {
                        let msg = "";
                        if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                            msg = "The user was re-invited successfully!";
                            Event("User Re-Invite", "User was re-invited", "Successful User Re-Invite");
                        }
                        else {
                            msg = "Failed to re-invite the user!";
                        }

                        this.setState({
                            loading: false,
                            message: msg
                        });

                        setTimeout(() => {
                            this.setState({
                                message: ""
                            });
                        }, 3000);
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({
                            loading: false,
                            message: "Failed to Re-Invite the user"
                        });

                        setTimeout(() => {
                            this.setState({
                                message: ""
                            });
                        }, 3000);
                    });
            })
        }
    }

    onTryReinviteAgain() {
        Event("Retry Reinvite", "User tried reinvite again in Customer Account", "Retry Reinvite");
        const user = this.state.currentUser;

        this.setState({
            reinviteFromUser: user == null ? false : true,
            reinviteEmail: user == null ? "" : user.email,
            reinviteSent: false, reinviteResult: false, reinviteLoading: false
        });
    }

    onInviteEmailChange(e) {
        const email = e.target.value;

        this.setState(prevState => ({
            currentAccount: {
                ...prevState.currentAccount,
                inviteEmail: email
            }
        }));
    }

    onSaveFamilyMember() {
        AccountService.invite(this.state.currentAccount);
        Event("Customer Invite", "User sent invite for customer account", "Customer Account Invite Sent");

        this.setState({
            showInvite: !this.state.showInvite
        });
    }

    setPartnerCallback() {
        //Needed for setCurrentPartnerAndCustomer but not used in this instance
        //console.log("Account setPartnerCallback called");
        let nextPath = this.getStorageValue("nextPath", null);

        this.props.history.push(nextPath);
        localStorage.setItem("nextPath", null);
    }

    getButton(user) {
        if (user.status) {
            return (
                <button
                    type="button"
                    className="secondary-btn btn-small"
                    onClick={() => this.showResetPasswordModal(user.email)}
                >
                    Reset Password
                </button>
            );
        }
        else {
            return (
                <div>{null}</div>
            );

        }
    }

    getCreateCaseButton(user) {
        if (user.status) {
            return (
                <button
                    type="button"
                    className="secondary-btn btn-small"
                    onClick={() => this.showCreateCasedModal(user)}
                >
                    Create Job
                </button>
            );
        }
        else {
            return (
                <div>{null}</div>
            );

        }
    }
    getStatus(status) {
        if (status) {
            return (
                <Tooltip title="User Sign-up Completed" enterDelay={200} leaveDelay={300} style={{ marginTop: '-5px', paddingTop: '-5px', backgroundColor: '#f7f9fa' }}>
                    <CircleIcon style={{ padding: '5px', color: 'green' }} />
                </Tooltip>
            );
        }
        else {
            return (
                <Tooltip title="User Sign-up Incomplete" enterDelay={200} leaveDelay={300} style={{ marginTop: '-5px', paddingTop: '-5px', backgroundColor: '#f7f9fa' }}>
                    <CircleIcon style={{ padding: '5px', color: 'red' }} />
                </Tooltip>
            );
        }
    }

    onInvite(users) {
        var apiCheck = true;
        var apiRoleId = '3';
        this.setState({ inviteLoading: true }, () => {
            AccountService.getpartnerinfo(this.state.currentAccount.accountId, true)
                .then(response => {
                    if (response.data.users != null && response.data.users.length > 0) {
                        let existingUsers = response.data.users;
                        // go through each user and see if they match a user in the system already
                        for (let i = 0; i < users.length; ++i) {
                            let currInviteUser = users[i];
                            for (let j = 0; j < existingUsers.length; ++j) {
                                // if there is a match, then check to see if they have 3 which is api in roleListId
                                if (currInviteUser.email.toUpperCase() == existingUsers[j].email.toUpperCase()) {
                                    if (existingUsers[j].roleListIds != null && existingUsers[j].roleListIds.includes(apiRoleId)) {
                                        // if so then we have an error
                                        apiCheck = false;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                    if (apiCheck) {
                        AccountService.inviteusersloop(this.state.currentAccount.name, this.state.currentAccount.accountId, users)
                            .then(response => {
                                if (response.data.length > 0) {
                                    this.setState(prevState => ({
                                        currentPartner: {
                                            ...prevState.currentPartner,
                                            users: response.data
                                        }
                                    }));
                                    this.setState({
                                        message: "The new user invites were sent successfully!", showInviteConfirm: !this.state.showInviteConfirm, inviteLoading: false
                                    });
                                    Event("Partner User Invite", "User clicked invite button", "Invite User Clicked");
                                }
                                else {
                                    this.setState({
                                        message: "Failed to send invites to the new users!", showInviteConfirm: !this.state.showInviteConfirm, inviteLoading: false
                                    });
                                }

                                setTimeout(() => {
                                    this.setState({
                                        message: ""
                                    });
                                }, 3000);
                            })
                    } else {
                        this.setState({ modalMessage: `This user email is already being used for the mobile application. Please try another email.`, modalTitle: "Mobile User" }, () => {
                            this.showInviteErrorModal();
                        });
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    renderAccount() {

        let Reparentbtn = (this.state.isAdmin) ?
            <button type="button" className="secondary-btn btn-small" onClick={() => this.openPartnerModal()}>
                Reparent Partner
            </button> : null;

        if (this.state.currentAccount.accountId) {
            let buttons = (this.props.isPartnerAdmin || this.state.isAdmin) ?
                <div className="table-container">
                    <button type="button" className="secondary-btn btn-small" onClick={() => this.showConfirmationModal()}>
                        Delete Account
                    </button>
                    {Reparentbtn}

                </div>
                : null;
            let contractType = this.props.attributeList?.find(x => (x.name === "ContractType" && x.organizationId === this.props.currentPartnerId))?.value;
            let isIncludedService = contractType === undefined || contractType === null ? false : contractType.toLowerCase().includes("service");
            let hasBilling = isIncludedService;
            if (isIncludedService && contractType.split(',').length > 1) {
                let customerBillingPlanId = this.state.currentAccount.attributes?.find(x => (x.name === "BillingPlanId"))?.value;
                hasBilling = customerBillingPlanId !== undefined ? customerBillingPlanId !== "3" : false;
            }

            let messageForApplyFee = hasBilling ? <h4>An early termination fee may apply</h4> : null;
            let customerId = (this.props.hasServiceTitan && this.props.hasBilling) ?
                <div style={{ marginBottom: '15px' }} >
                    <label>Service Titan Customer Id</label>
                    <input
                        type="text"
                        className="form-control"
                        id="customerId"
                        value={this.state.currentAccount !== null && this.state.currentAccount !== undefined && this.state.currentAccount.customerRecordId !== null ? this.state.currentAccount.customerRecordId : ""}
                        onChange={this.onChangeCustomerRecordId}
                    />
                </div>
                : null

            let addNewUserRow = <tr key={`add-fam-member`}>
                <td> (+ Invite Another User) </td>
                <td></td>
                <td>
                    <button
                        type="submit"
                        className="secondary-btn btn-small"
                        onClick={() => this.openInviteUserModal(null)}
                    >
                        Invite User </button>
                </td>
                <td>
                    <Tooltip title="All Customer Users Will Appear Here" enterDelay={200} leaveDelay={400} style={{ marginTop: '-5px', paddingTop: '-5px', backgroundColor: '#f7f9fa' }}>
                        <CircleIcon style={{ padding: '5px', color: 'gray' }} />
                    </Tooltip>
                </td>
            </tr>;

            let userTableContent = this.state.currentAccount.familyMembers.length ?
                <tbody>
                    {this.state.currentAccount.familyMembers.map(familyMembers =>
                        <tr key={familyMembers.userId}>
                            <td>{familyMembers.name}</td>
                            <td>{familyMembers.email}</td>
                            <td>
                                {(!this.state.isCustomerSupport) && (<div>
                                    <button
                                        type="submit"
                                        className="secondary-btn btn-small"
                                        onClick={() => this.deleteUser(familyMembers.userId)}
                                    >
                                        Delete User </button>
                                    {this.getButton(familyMembers)}
                                    {this.getCreateCaseButton(familyMembers)}
                                </div>)}
                            </td>
                            <td>{this.getStatus(familyMembers.status)}</td>
                        </tr>
                    )}
                    {addNewUserRow}

                </tbody>
                : <tbody>
                    <tr key={`no-fam-members`}>
                        <td>Pending User Signup</td>
                        <td></td>
                        <td>
                            <button
                                type="submit"
                                className="secondary-btn btn-small"
                                onClick={() => this.openReinviteHomeownerModal(null)}
                            >
                                Invite </button>

                            <button
                                type="submit"
                                className="secondary-btn btn-small"
                                onClick={() => this.openActivationLinkModal(null)}
                            >
                                Get Link </button>
                        </td>
                        <td>
                            <Tooltip title="User Sign-up Incomplete. To re-invite the customer click the Invite button" enterDelay={200} leaveDelay={400} style={{ marginTop: '-5px', paddingTop: '-5px', backgroundColor: '#f7f9fa' }}>
                                <CircleIcon style={{ padding: '5px', color: 'red' }} />
                            </Tooltip>
                        </td>
                    </tr>
                </tbody>;

            return (
                <div>
                    <div className="row no-gutters">
                        <div className="white-container col-sm-12 col-md-9 col-lg-6">
                            <form className="input-container">
                                <div>
                                    <label>First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="first-name"
                                        value={this.state.currentAccount.firstName}
                                        onChange={this.onChangeFirstName}
                                    />
                                </div>
                                <div>
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="last-name"
                                        value={this.state.currentAccount.lastName}
                                        onChange={this.onChangeLastName}
                                    />
                                </div>
                                <div style={{ marginBottom: '15px' }} >
                                    <PhoneNumberInput
                                        phoneNumber={this.state.currentAccount.phone}
                                        setPhoneNumber={this.onChangePhone}
                                        onError={this.handleError}
                                    />
                                </div>
                                {customerId}

                                <div style={{ marginBottom: '10px', height: '25px' }}>
                                    {(!this.state.isCustomerSupport) && (
                                        <div className="btn-right" style={{ marginRight: '15px' }}>
                                            <button
                                                type="button"
                                                className="secondary-btn btn-small"
                                                onClick={this.updateAccount}
                                                disabled={!!this.state.error || !this.state.currentAccount.phone || this.state.isUpdating}>
                                                {this.state.isUpdating ? 'Updating...' : 'Update'}
                                            </button>
                                        </div>)}
                                </div>
                            </form>

                        </div>
                    </div>
                    <br />
                    {buttons}

                    <div className="table-container">
                        <table className='table table-striped table-bordered table-sm' aria-labelledby="tabelLabel">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Actions</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            {userTableContent}
                        </table>
                    </div>

                    <button
                        type="button"
                        className="btn-main"
                        onClick={() => this.openInviteFamilyMember()}
                        style={{ visibility: 'hidden' }}
                    >
                        Add
                    </button>
                    <p className="status-msg">{this.state.famMessage}</p>

                    <SelectPartnerDataModal onClick={this.showPartnerModal} show={this.state.showPartner} onPartnerSelect={this.onChangeParent} onSave={this.onSave} partners={this.state.partnerList} mySelectedPartner={this.state.currentAccount.parentAccountId} />

                    <InviteFamilyMemberModal onClick={this.showInviteModal} show={this.state.showInvite} onSave={this.onSaveFamilyMember} onInviteEmailChange={this.onInviteEmailChange} />

                    <ForgotPasswordModal onClick={this.showResetPasswordModal} show={this.state.showResetPassword} currentAccount={this.state.currentAccount} passwordEmailSent={this.props.passwordEmailSent} emailToReset={this.state.emailToReset} endForgotPassword={this.showResetPasswordModal} isMobile={this.props.isMobile} isAdmin={this.state.isAdmin} />

                    <ReinviteHomeownerModal onClick={this.showReinviteHomeownerModal} show={this.state.showReinviteHomeowner} reinviteLoading={this.state.reinviteLoading} reinviteSent={this.state.reinviteSent} reinviteResult={this.state.reinviteResult} reinviteFromUser={this.state.reinviteFromUser} onConfirm={this.onReinviteHomeowner} onEmailChange={this.onReinviteEmailChange} email={this.state.reinviteEmail} onTryAgain={this.onTryReinviteAgain} />

                    <EmailLinkModal onClick={this.showActivationLinkModal} show={this.state.showActivationLink} reinviteLoading={this.state.reinviteLoading} linkObj={this.state.currentLinkObj} linkUrl={this.state.currentLinkObj !== null && this.state.currentLinkObj !== undefined ? this.state.currentLinkObj.linkUrl : null} />

                    <InviteUserModal onClick={this.showInviteUserModal} show={this.state.showInviteConfirm} onInvite={this.onInvite} inviteLoading={this.state.inviteLoading} roles={this.props.roles} isCustomerAccount={true}></InviteUserModal>
                    {this.state.showCreateCase && <CreateCaseModel closeCreateCaseModle={this.showCreateCasedModal} createCase={this.createCaseAsync} />}

                    <ConfirmPasswordModal
                        show={this.state.showPasswordConfirmation}
                        onCancelClick={this.showPasswordConfirmationModal}
                        onYes={this.showDeleteAccounModal}
                        onLogOff={this.props.onLogOff} />

                    <ConfirmationModal
                        show={this.state.showConfirmation}
                        onYes={this.showPasswordConfirmationModal}
                        onClick={this.cancelAccountDelete}
                        message={<>{messageForApplyFee}<UserAssetList data={this.state.userassethierarchy} /></>}

                        title={this.state.modalTitle} />

                    <ConfirmationModal
                        show={this.state.showUnsavedModal == null ? this.getStorageValue("showUnsavedModal", false) === 'true' : this.state.showUnsavedModal}
                        onYes={this.onSaveThenContinue}
                        onClick={this.onContinueWithoutSaving}
                        message="You have unsaved changes, would like to save before leaving?"
                        title="Unsaved Changes" />

                    <ConfirmDeleteAccountModal
                        onClick={this.showDeleteAccounModal}
                        show={this.state.showDeleteAccount}
                        onConfirm={this.deleteAccount}
                        accountId={this.state.currentAccount.accountId}
                        messageBody={this.state.message}
                        loading={this.state.waitDelete}
                        isDeleted={this.state.isDeleted}
                        isActiveBillingPlan={this.state.isActiveBillingPlan}
                        onCancelClick={this.cancelAccountDelete}
                    />
                    <Snackbar open={this.state.showMessage} message={this.state.message} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} />
                </div>
            );
        }
        else {
            return (
                <div>
                    <p className="status-msg">No customer account information to display.</p>
                </div>
            );
        }
    }

    render() {
        const { classes } = this.props;
        let contents = this.state.loading
            ? <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <h3 className="status-msg" style={{ textAlign: "center", color: "white" }}>{this.props.reparentStatus}</h3>
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div>
            </div>
            : this.renderAccount();

        return (
            <div className={classes.root}>
                <div className='infoPage'>
                    <h3 className="pageTitle" id="tabelLabel" >Customer Account</h3>
                    {contents}
                    <h5 style={{ padding: '10px' }}>Email History</h5>
                    <EmailStatusTable messages={this.state.messages} senders={this.state.senders} isLoading={this.state.tableLoading} isMounted={this._isMounted} />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Account);

// Commented out code for Account display - LFWCS-68
//<div className="txt-wide">
//    <label htmlFor="description">Email</label>
//    <input
//        type="text"
//        className="form-control"
//        id="email"
//        value={this.state.currentAccount.email}
//        onChange={this.onChangeEmail}
//    />
//</div>
