/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import { PageView, ModalView, Event } from "../GoogleAnalytics";
import Snackbar from '@material-ui/core/Snackbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import IconTooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RefreshIcon from '@material-ui/icons/Refresh';
import MenuIcon from '@material-ui/icons/Menu';
import AlertService from '../../services/alerts.service';
import AssetService from '../../services/asset.service';
import IdleTimer from 'react-idle-timer';
import IdleTriageModal from './IdleTriageModal';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import AlertTagsModal from './AlertTagsModal';
import AlertTypeModal from './AlertTypeModal';
import ViewGraphButton from '../TriageAlerts/ViewGraphButton';
import RetrainAssetModal from './RetrainAssetModal';
import TriageMenuModal from './TriageMenuModal';
import memoize from 'memoize-one';
import moment from 'moment';
import tinycolor from "tinycolor2";

import HighchartGraph from '../Graph/HighchartGraph';

import { jsAsset, alertCustomer, jsSession, assetType } from "../../componentObjects";
//import getLast150, { breakAlertData } from '../../processAlertDataArray';

import { withStyles } from '@material-ui/core/styles';
import triagecheckoutService from "../../services/triagecheckout.service";
import triageService from "../../services/triage.service";

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important'
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    },
    menuIcon: {
        border: '2px solid #0033a0',
        borderRadius: '0.75rem'
    }
});

class InternalTriageAlerts extends Component {
    static displayName = InternalTriageAlerts.name;
    _isMounted = false;
    //pastSessionStart = "";

    constructor(props) {
        super(props);

        this.idleTimer = null;
        this.handleOnAction = this.handleOnAction.bind(this);
        this.handleOnActive = this.handleOnActive.bind(this);
        this.handleOnIdle = this.handleOnIdle.bind(this);
        this.onShowIdleModal = this.onShowIdleModal.bind(this);

        this.createSession = this.createSession.bind(this);
        this.publishSession = this.publishSession.bind(this);

        this.getAlerts = this.getAlerts.bind(this);
        this.reloadTable = this.reloadTable.bind(this);
        this.reloadAssetAlerts = this.reloadAssetAlerts.bind(this);
        this.changeRadio = this.changeRadio.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);

        this.getQueueCounts = this.getQueueCounts.bind(this);
        this.updateQueueCounts = this.updateQueueCounts.bind(this);
        this.getAlertCount = this.getAlertCount.bind(this);

        this.onStartSessionClick = this.onStartSessionClick.bind(this);
        this.onSkipAssetClick = this.onSkipAssetClick.bind(this);

        this.onBackClick = this.onBackClick.bind(this);
        this.onViewAllAlertsClick = this.onViewAllAlertsClick.bind(this);
        this.onAlertAssetClick = this.onAlertAssetClick.bind(this);
        this.onAlertTypeClick = this.onAlertTypeClick.bind(this);
        this.onCustomerInfoClick = this.onCustomerInfoClick.bind(this);
        this.onAssetInfoClick = this.onAssetInfoClick.bind(this);
        this.onEscalatedAlertsClick = this.onEscalatedAlertsClick.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.handleAlertCheck = this.handleAlertCheck.bind(this);
        this.handleLongAlertCheck = this.handleLongAlertCheck.bind(this);
        this.onGraphAll = this.onGraphAll.bind(this);
        this.handleGraphAlertCheck = this.handleGraphAlertCheck.bind(this);

        this.setLoading = this.setLoading.bind(this);

        this.handleColorClick = this.handleColorClick.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.closeColorPicker = this.closeColorPicker.bind(this);

        this.onForceReloadClick = this.onForceReloadClick.bind(this);
        this.openForceReloadConfirmModal = this.openForceReloadConfirmModal.bind(this);
        this.showForceReloadConfirmModal = this.showForceReloadConfirmModal.bind(this);

        this.onLongAlertClick = this.onLongAlertClick.bind(this);
        this.openLongAlertConfirmModal = this.openLongAlertConfirmModal.bind(this);
        this.showLongAlertConfirmModal = this.showLongAlertConfirmModal.bind(this);

        this.onEscalateClick = this.onEscalateClick.bind(this);
        this.openEscalateConfirmModal = this.openEscalateConfirmModal.bind(this);
        this.showEscalateConfirmModal = this.showEscalateConfirmModal.bind(this);

        this.onFalsePositiveClick = this.onFalsePositiveClick.bind(this);
        this.openFalsePositiveConfirmModal = this.openFalsePositiveConfirmModal.bind(this);
        this.showFalsePositiveConfirmModal = this.showFalsePositiveConfirmModal.bind(this);

        this.onCloseInternalClick = this.onCloseInternalClick.bind(this);
        this.openCloseInternalConfirmModal = this.openCloseInternalConfirmModal.bind(this);
        this.showCloseInternalConfirmModal = this.showCloseInternalConfirmModal.bind(this);

        this.onAddTagsClick = this.onAddTagsClick.bind(this);
        this.openAddTagsModal = this.openAddTagsModal.bind(this);
        this.showAddTagsModal = this.showAddTagsModal.bind(this);

        this.onChangeAlertTypeClick = this.onChangeAlertTypeClick.bind(this);
        this.openChangeAlertTypeModal = this.openChangeAlertTypeModal.bind(this);
        this.showChangeAlertTypeModal = this.showChangeAlertTypeModal.bind(this);

        this.onUnitEKGClick = this.onUnitEKGClick.bind(this);
        this.openUnitEKGConfirmModal = this.openUnitEKGConfirmModal.bind(this);
        this.showUnitEKGConfirmModal = this.showUnitEKGConfirmModal.bind(this);

        this.onUnitAlertClick = this.onUnitAlertClick.bind(this);
        this.openUnitAlertConfirmModal = this.openUnitAlertConfirmModal.bind(this);
        this.showUnitAlertConfirmModal = this.showUnitAlertConfirmModal.bind(this);

        this.onRetrainClick = this.onRetrainClick.bind(this);
        this.openRetrainConfirmModal = this.openRetrainConfirmModal.bind(this);
        this.showRetrainConfirmModal = this.showRetrainConfirmModal.bind(this);

        this.getAlertTypeCount = this.getAlertTypeCount.bind(this);
        this.getEscalatedAlertTypeCount = this.getEscalatedAlertTypeCount.bind(this);
        this.getGraphButton = this.getGraphButton.bind(this);
        this.getRunCount = this.getRunCount.bind(this);
        this.showGraphModal = this.showGraphModal.bind(this);

        this.handleMoreMenu = this.handleMoreMenu.bind(this);
        this.handleCloseMoreMenu = this.handleCloseMoreMenu.bind(this);
        this.handleStartMenu = this.handleStartMenu.bind(this);
        this.handleCloseStartMenu = this.handleCloseStartMenu.bind(this);
        this.openSkipsModal = this.openSkipsModal.bind(this);
        this.showSkipsModal = this.showSkipsModal.bind(this);
        this.openCheckoutsModal = this.openCheckoutsModal.bind(this);
        this.showCheckoutsModal = this.showCheckoutsModal.bind(this);
        this.clearUserSkips = this.clearUserSkips.bind(this);
        this.clearCheckouts = this.clearCheckouts.bind(this);

        this.state = {
            message: "",
            showMessage: false,
            modalMessage: "",
            modalTitle: "",
            currentAsset: {},
            currentType: "",
            currentRadio: "Furnace",
            alertsByAssetDict: {},
            assetList: [],
            assetAlertList: [],
            showGraphModal: false,
            typeAlertList: [],
            falsePosAlertsList: [],
            escalatedAlertsObj: {},
            loading: true,
            alertTableLoading: false,
            assetSelected: false,
            isSelected: -1,
            alertTypeSelected: false,
            typeSelected: -1,
            selectedTypeId: -1,
            selectedAlerts: [],
            showModals: [],
            graphedAlerts: [],
            dataList: [],
            chartDataList: [],
            rawDataList: [],
            last150DataList: [],
            longAlertList: [],
            currentLongAlert: {},
            alertColors: [],
            alertData: {},
            dataMax: 0,
            rawMax: 0,
            currentMax: 0,
            allSelected: false,
            allGraphed: false,

            showAllAlerts: false,
            showCustomerInfo: false,
            currentCustomer: alertCustomer,
            showAssetInfo: false,
            currentAssetInfo: jsAsset,
            showEscalatedAlerts: false,
            escalatedAlertsLoading: false,

            showRetrainConfirm: false,
            showForceReloadConfirm: false,
            showLongAlertConfirm: false,
            showEscalateConfirm: false,
            showFalsePositiveConfirm: false,
            showCloseInternalConfirm: false,
            showAddTags: false,
            showChangeType: false,
            showUnitEKGConfirm: false,
            showUnitAlertConfirm: false,
            unitEKGUrl: "",
            unitAlertUrl: "",

            alertTypes: [],
            changeAlertType: -1,
            alertTags: [],
            selectedTags: [],
            selectedColor: -1,
            showColorPicker: false,
            visibility: {
                rla: false,
                lra: false,
                fla: false
            },
            startSessionLoading: false,
            sessionStarted: false,
            timeout: 1000 * 5 * 60, 
            showIdleModal: false,
            isTimedOut: false,
            idleTime: null,
            triageTimer: null,
            currentUser: {},
            triageUserId: -1,
            triageSession: {
                //id: 0,
                startDateTimeStr: "",
                endDateTimeStr: "",
                userId: 0,
                user: {
                    //id: 0, 
                    userId: 0,
                    name: "",
                    email: ""
                },
                //dailyId: 0, 
                triagedAlerts: []
            },
            triagedAlertsList: [],
            analyzeIgnitionData: {
                delayLow: false,
                ignitionLow: false,
                ignitionHigh: false,
                delayHigh: false,
                inductionOffAmpsMax: false,
	            },
            analyzeShutdownData: {
                gasValveOffLow: false,
                gasValveOffHigh: false,
                igniterOnAmps: false,
                shutdownLow: false,
                shutdownHigh: false,
	        },
            showAnalyzeIgnitionData:false,
            showanAlyzeShutdownData :false,
            AnalyzeData: [],

            currentQueueAsset: {},
            currentQueueIndex: -1,
            queueCounts: {
                "Furnace": {
                    numAssets: 0,
                    numAlerts: 0
                },
                "AirConditioner": {
                    numAssets: 0,
                    numAlerts: 0
                },
                "Other": {
                    numAssets: 0,
                    numAlerts: 0
                }
            },

            moreModalLoading: false,
            modalList: [],
            anchorEl: null,
            openMoreMenu: false,
            openStartMenu: false,
            showSkips: false,
            showCheckouts: false,
            clearedSkips: false,
            clearedSkipsSuccess: false,
            clearedCheckouts: false,
            clearedCheckoutsSuccess: false,
            selectedCheckouts: [],
            sortByAlertCount: true
        }
    }

    componentDidMount() {   
        this._isMounted = true;
        PageView();

        if (!this.props.isLoading) {
            this.getAlerts();
            //this.createSession();
        }
    }

    componentWillUnmount() {
        if (this.state.triageSession.id !== null && this.state.triageSession.id !== undefined && this.state.triageSession.id > 0) {
            this.publishSession();
        }

        this._isMounted = false;
    }

    memSession = memoize(this.createSession);
    memAlerts = memoize(this.getAlerts);

    createSession(sortByAlertCount = true) {
        var timerStart = moment();
        var triageUser = {
            userId: parseInt(this.props.userId, 10),
            name: this.props.currentUser.firstName + " " + this.props.currentUser.lastName,
            email: this.props.currentUser.email
        };
        var session = {
            startDateTimeStr: timerStart.format(),
            endDateTimeStr: timerStart.format(),
            user: triageUser,
            triagedAlerts: []
        };
        
        this.setState({
            triageSession: session,
            triagedAlertsList: []
        }, async () => {
            await triageService.createtriagesession(this.state.triageSession)
                .then(response2 => {
                    if (this._isMounted)
                        this.setState({
                            triageSession: response2,
                            triageUserId: response2.userId
                        }, async () => {
                                //this.getAlerts();

                                let triageInfo = this.sortTriageAlertAssets(sortByAlertCount);
                                let checkoutTime = moment().format();

                                await triagecheckoutService.getuntriagedqueueasset(this.state.triageSession.userId, checkoutTime, triageInfo.triageAlertsAssets)
                                    .then(response => {
                                        if (this._isMounted) {
                                            let queueItem = response;
                                            let queueIndex = triageInfo.triageAlertsAssets.findIndex(a => parseInt(a.assetId) === parseInt(queueItem.assetId));
                                            
                                            this.setState({
                                                currentQueueAsset: queueItem,
                                                currentQueueIndex: queueIndex,
                                                startSessionLoading: false
                                            }, () => {
                                                if (this.state.currentQueueIndex >= 0)
                                                    this.onAlertAssetClick(queueIndex);
                                            });
                                        }
                                    })
                                    .catch(e => {
                                        console.log(e);
                                    })
                        });
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    sortTriageAlertAssets(sortByAlertCount = true) {
        const updatedRadioDict = {...this.state.radioDict}
        let assetList = updatedRadioDict[this.state.currentRadio].triageAlertsAssets;
        if (sortByAlertCount === true) {
            assetList = assetList.sort((a,b) => { return b.alertCount - a.alertCount })
        }
        else { // Sort By Oldest Alert
            assetList = assetList.sort((a,b) => { return new Date(a.oldestAlertTime) - new Date(b.oldestAlertTime) })
        }
        updatedRadioDict[this.state.currentRadio].triageAlertsAssets = assetList;
        this.setState({ radioDict: updatedRadioDict, sortByAlertCount: sortByAlertCount });
        return updatedRadioDict[this.state.currentRadio];
    }
    getAlerts() { //async
        this.setState({
            furnaceDict: {
                assetList: [],
                alertsByAssetDict: {}
            },
            acDict: {
                assetList: [],
                alertsByAssetDict: {}
            },
            otherDict: {
                assetList: [],
                alertsByAssetDict: {}
            },
            radioDict: {},
            assetList: [],
            assetAlertList: [],
            dataList: [],
            chartDataList: [],
            rawDataList: [],
            last150DataList: [],
            longAlertList: [],
            selectedTags: [],
            selectedAlerts: [],
            alertsByAssetDict: {},
            currentLongAlert: {},
            currentAsset: {},
            currentCustomer: alertCustomer,
            currentPartnerName: "",
            currentRadio: "Furnace",
            assetSelected: false,
            alertTypeSelected: false,
            showCustomerInfo: false,
            showColorPicker: false,
            allSelected: false,
            selectedColor: -1,
            isSelected: -1,
            typeSelected: -1,
            selectedTypeId: -1,
            loading: true,
            graphedAlerts: [],
            allGraphed: false,
            showAllAlerts: false
        }, async () => {
                await AlertService.getuntriagedalerts() 
                    .then(response => {
                        if (this._isMounted)
                            this.setState({
                                radioDict: response.data,
                                furnaceDict: response.data["Furnace"],
                                acDict: response.data["AirConditioner"],
                                otherDict: response.data["Other"],
                            }, () => {
                                this.setState({
                                        assetList: this.state.furnaceDict !== null && this.state.furnaceDict !== undefined ? this.state.furnaceDict.triageAlertsAssets : [], //this.state.furnaceDict?.triageAlertsAssets,
                                        alertsByAssetDict: this.state.furnaceDict !== null && this.state.furnaceDict !== undefined ? this.state.furnaceDict.alertsByAssetDict : {}, //this.state.furnaceDict?.alertsByAssetDict,
                                        loading: false,
                                        alertTableLoading: false
                                    }, () => {
                                            this.getQueueCounts(this.state.radioDict);
                                    });
                            });
                    })
                    .catch(e => {
                        console.log(e);
                    });
        });
    }

    getRefreshAlerts(triagedAlertIds = null) {
        this.setState({
            furnaceDict: {
                assetList: [],
                alertsByAssetDict: {}
            },
            acDict: {
                assetList: [],
                alertsByAssetDict: {}
            },
            otherDict: {
                assetList: [],
                alertsByAssetDict: {}
            },
            radioDict: {},
            assetList: [],
            assetAlertList: [],
            dataList: [],
            chartDataList: [],
            rawDataList: [],
            last150DataList: [],
            longAlertList: [],
            selectedTags: [],
            selectedAlerts: [],
            alertsByAssetDict: {},
            currentLongAlert: {},
            currentAsset: {},
            currentCustomer: alertCustomer,
            currentPartnerName: "",
            //currentRadio: "Furnace",
            //assetSelected: false,
            alertTypeSelected: false,
            showCustomerInfo: false,
            showColorPicker: false,
            allSelected: false,
            selectedColor: -1,
            //isSelected: -1,
            typeSelected: -1,
            selectedTypeId: -1,
            loading: true,
            graphedAlerts: [],
            allGraphed: false,
            showAllAlerts: false
        }, async () => {
                await AlertService.getfiltereduntriagedalerts(null, triagedAlertIds)
                    .then(response => {
                        if (this._isMounted)
                            this.setState({
                                radioDict: response.data,
                                furnaceDict: response.data["Furnace"],
                                acDict: response.data["AirConditioner"],
                                otherDict: response.data["Other"],
                            }, () => {
                                let currentDict = this.sortTriageAlertAssets(this.state.sortByAlertCount); //this.state.radioDict[this.state.currentRadio];
                                this.setState({
                                    assetList: currentDict !== null ? currentDict.triageAlertsAssets : [], 
                                    alertsByAssetDict: currentDict !== null ? currentDict.alertsByAssetDict : {}, 
                                    loading: false,
                                    alertTableLoading: false
                                }, async () => {
                                        this.getQueueCounts(this.state.radioDict);

                                        let checkoutTime = moment().format();

                                        await triagecheckoutService.getuntriagedqueueasset(this.state.triageSession.userId, checkoutTime, this.state.assetList)
                                            .then(response => {
                                                if (this._isMounted) {
                                                    let queueItem = response;
                                                    let queueIndex = this.state.assetList.findIndex(a => parseInt(a.assetId) === parseInt(queueItem.assetId));

                                                    this.setState({
                                                        currentQueueAsset: queueItem,
                                                        currentQueueIndex: queueIndex
                                                    }, () => {
                                                        if (this.state.currentQueueIndex >= 0)
                                                            this.setState({
                                                                assetSelected: true,
                                                                isSelected: queueIndex
                                                            }, () => {
                                                                this.onAlertAssetClick(queueIndex);
                                                            });
                                                        else
                                                            this.setState({
                                                                assetSelected: false,
                                                                isSelected: -1,
                                                                loading: false
                                                            });
                                                    });
                                                }
                                            })
                                            .catch(e => {
                                                console.log(e);
                                            })
                                });
                            });
                    })
                    .catch(e => {
                        console.log(e);
                    });
        });
    }

    reloadTable() {
        this.setState({
            alertTableLoading: true,
            assetList: [],
            selectedAlerts: [],
            alertTypeSelected: false,
            typeSelected: -1,
            graphedAlerts: []
        }, () => {
                this.getAlerts();
        });
    }

    async forceReload() {
        // todo ?? 
    }

    getQueueCounts(radioDict) {
        let furnDict = radioDict["Furnace"] !== undefined ? radioDict["Furnace"] : { triageAlertsAssets: [] };
        let acDict = radioDict["AirConditioner"] !== undefined ? radioDict["AirConditioner"] : { triageAlertsAssets: [] };
        let otherDict = radioDict["Other"] !== undefined ? radioDict["Other"] : { triageAlertsAssets: [] };

        let queueCounts = {
            "Furnace": {
                numAssets: furnDict.triageAlertsAssets.length,
                numAlerts: this.getAlertCount(furnDict.triageAlertsAssets)
            },
            "AirConditioner": {
                numAssets: acDict.triageAlertsAssets.length,
                numAlerts: this.getAlertCount(acDict.triageAlertsAssets)
            },
            "Other": {
                numAssets: otherDict.triageAlertsAssets.length,
                numAlerts: this.getAlertCount(otherDict.triageAlertsAssets)
            }
        };
        
        this.setState({ queueCounts: queueCounts });
    }

    updateQueueCounts(radio, assets) {
        let queueCounts = this.state.queueCounts;

        queueCounts[radio] = {
            numAssets: assets.length,
            numAlerts: this.getAlertCount(assets)
        };

        this.setState({ queueCounts: queueCounts });
    }

    getAlertCount(assetList) {
        let alertCountsArr = assetList.map((asset) => (asset.alertCount));
        let alertCount = 0;
        
        if (alertCountsArr.length > 0) {
            alertCount = alertCountsArr.reduce((a, sum) => a + sum, 0);
        }

        return alertCount;
    }

    //more menu
    handleMoreMenu(event) {
        this.setState({
            anchorEl: event.currentTarget,
            openMoreMenu: !this.state.openMoreMenu
        });
    }

    handleCloseMoreMenu() {
        this.setState({
            openMoreMenu: false
        })
    }

    //start menu
    handleStartMenu(event) {
        this.setState({
            anchorEl: event.currentTarget,
            openStartMenu: !this.state.openStartMenu
        });
    }

    handleCloseStartMenu() {
        this.setState({
            openStartMenu: false
        })
    }
    
    showCheckoutsModal() {
        this.setState({
            showCheckouts: !this.state.showCheckouts
        }, () => {
            if (this.state.showCheckouts)
                ModalView('currentCheckouts');
        });
    }

    openCheckoutsModal() {
        this.setState({
            showCheckouts: true,
            openMoreMenu: false
        }, () => {
            this.setState({ moreModalLoading: true, modalList: [], clearedSkips: false, clearedSkipsSuccess: false, clearedCheckouts: false, clearedCheckoutsSuccess: false }, async () => {
                await triagecheckoutService.getcurrentcheckouts()
                    .then(response => {
                        if (this._isMounted) {
                            this.setState({ modalList: response, moreModalLoading: false });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            });
        })
    }

    showSkipsModal() {
        this.setState({
            showSkips: !this.state.showSkips
        }, () => {
            if (this.state.showSkips)
                ModalView('skippedAssets');
        });
    }

    openSkipsModal() {
        this.setState({
            showSkips: true,
            openMoreMenu: false
        }, () => {
            this.setState({ moreModalLoading: true, modalList: [], clearedSkips: false, clearedSkipsSuccess: false, clearedCheckouts: false, clearedCheckoutsSuccess: false }, async () => {
                let userEmail = this.state.triageUserId >= 0 ? null : this.props.currentUser.email;
                await triagecheckoutService.getskippedassets(this.state.triageUserId, userEmail)
                    .then(response => {
                        if (this._isMounted) {
                            this.setState({ modalList: response, moreModalLoading: false });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            })
        })
    }

    clearUserSkips() {
        Event("Clear users skipped assets", "User cleared their skipped assets", "Clear users skipped assets");
        this.setState({ moreModalLoading: true, clearedSkips: true, clearedSkipsSuccess: false }, async () => {
            let userId = this.state.triageUserId >= 0 ? this.state.triageUserId : this.state.modalList[0].userId;
            await triagecheckoutService.clearuserskips(userId/*this.state.triageSession.userId*/)
                .then(response => {
                    let clearSuccess = false;
                    if (response !== undefined && response !== null && response !== false) {
                        clearSuccess = true;
                    }

                    this.setState({ moreModalLoading: false, clearedSkipsSuccess: clearSuccess });
                })
                .catch(e => {
                    console.log(e);
                })
        })
    }

    clearCheckouts() {
        Event("Cleared asset checkouts", "User cleared all asset checkouts", "Cleared asset checkouts");
        this.setState({ moreModalLoading: true, clearedCheckouts: true, clearedCheckoutsSuccess: false }, async () => {
            //todo ? selecting checkouts ?
            await triagecheckoutService.clearcheckouts(true, null)
                .then(response => {
                    let clearSuccess = false;
                    if (response !== undefined && response !== null && response !== false) {
                        clearSuccess = true;
                    }

                    this.setState({ moreModalLoading: false, clearedCheckoutsSuccess: clearSuccess }, () => {
                        this.onBackClick();
                    });
                })
                .catch(e => {
                    console.log(e);
                })
        })
    }

    //for idle session end
    handleOnAction() {
        this.setState({ isTimedOut: false, idleTime: null });
    }

    handleOnActive() {
        this.setState({ isTimedOut: false, idleTime: null });
    }

    handleOnIdle() {
        if (!this.state.showIdleModal) {
            let idleTime = moment().format();
            this.setState({ showIdleModal: true, idleTime: idleTime }, () => {
                this.idleTimer.reset();
                ModalView("/idleTriage");
            })
        }
        else {
            Event("Triage Session Idle Timeout", "Triage session timed out after user idled for 5 minutes", "Triage Session Idle Timeout");
            this.setState({ showIdleModal: false, isTimedOut: true }, async () => {
                if (this.state.sessionStarted) {
                    await this.publishSession();
                    this.onBackClick();
                }
            })
        }
    }

    async publishSession() {
        let session = this.state.triageSession;
        let sessionAlerts = this.state.triagedAlertsList;//session.triagedAlerts;
        /*for (var i = 0; i < this.state.selectedAlerts.length; i++) {
            let thisAlert = this.state.selectedAlerts[i];
            let tAlert = {
                alertId: parseInt(thisAlert.alertId),
                //sessionId: parseInt(session.id), 
                statusId: 1,
                statusName: "Escalated",
                alertTypeId: parseInt(thisAlert.typeId),
                alertTypeName: thisAlert.type,
                assetTypeId: assetType[this.state.currentAsset.assetType],
                assetType: this.state.currentAsset.assetType,
                assetId: parseInt(this.state.currentAsset.assetId)
            };
            sessionAlerts = sessionAlerts.concat(tAlert);
        }*/
        session.endDateTimeStr = this.state.idleTime == null ? moment().format() : this.state.idleTime;
        session.triagedAlerts = sessionAlerts;
        await triageService.publishtriagesession(session)
            .then(response => {
                if (this._isMounted) {
                    if (response.status === 200)
                        this.setState({ message: "Successfully published triage session!", triageSession: jsSession, triagedAlertsList: [], idleTime: null });
                    else
                        this.setState({ message: "Failed to publish triage session.", triageSession: jsSession, triagedAlertsList: [], idleTime: null });

                    setTimeout(() => {
                        this.setState({
                            message: ""
                        });
                    }, 3000);
                }
            })
            .catch(e => {
                console.log(e);
            });

        await triagecheckoutService.finishasset(this.state.currentQueueAsset)
            .then(response2 => {
                if (this._isMounted) {
                    if (response2)
                        this.setState({ message: "Successfully removed asset from checkout table!" });
                    else
                        this.setState({ message: "Failed to remove asset from checkout table." });

                    setTimeout(() => {
                        this.setState({
                            message: ""
                        });
                    }, 3000);
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    onShowIdleModal = e => {
        this.setState({
            showIdleModal: !this.state.showIdleModal,
            isTimedOut: false
        }, () => {
            if (this.state.showIdleModal)
                ModalView("idleTriage");
        });
        window.scrollTo(0, 0);
    }

    onIdleRestart = e => {
        this.setState({
            showIdleModal: !this.state.showIdleModal,
            isTimedOut: false,
            idleTime: null
        }, () => {
                this.idleTimer.reset();
        });
    }
    // end idle

    showGraphModal(index = 0) {
        let showArray = [...this.state.showModals];
        let show = showArray[index];
        showArray[index] = !show;

        this.setState({ showModals: showArray });
    }

    reloadAssetAlerts(id) {
        const assetId = id;
        let assetList = this.state.assetList;
        let alertsByAssetDict = this.state.alertsByAssetDict;

        this.setState({
            alertTableLoading: true,
            selectedAlerts: [],
            dataList: [],
            chartDataList: [],
            rawDataList: [],
            last150DataList: [],
            longAlertList: [],
            typeAlertList: [],
            alertTypeSelected: false,
            showCustomerInfo: false,
            showAssetInfo: false,
            showEscalatedAlerts: false,
            showColorPicker: false,
            allSelected: false,
            selectedColor: -1,
            typeSelected: -1,
            selectedTypeId: -1,
            currentType: "",
            currentLongAlert: {},
            graphedAlerts: [],
            allGraphed: false,
            showAllAlerts: false 
        }, async () => {
                await AlertService.getfiltereduntriagedalerts(assetId,this.state.triagedAlertsList.map(t => t.alertId.toString()))
                    .then(response => {
                        if (this._isMounted) {
                            if (response.data[this.state.currentRadio].triageAlertsAssets.length) {
                                let newAsset = response.data[this.state.currentRadio].triageAlertsAssets[0];
                                let newAssetAlerts = response.data[this.state.currentRadio].alertsByAssetDict[assetId];
                                let triagedAlertIds = this.state.triagedAlertsList.map(t => t.alertId.toString());
                                let filterAssetAlerts = newAssetAlerts.filter(a => !triagedAlertIds.includes(a.alertId.toString()));//this.state.triagedAlertsList.findIndex(t => t.alertId.toString() === a.alertId.toString()) === -1);
                                
                                if (filterAssetAlerts.length === 0) {
                                    this.getRefreshAlerts(triagedAlertIds);
                                }
                                else {
                                    if (filterAssetAlerts.length !== newAssetAlerts.length) {
                                        newAssetAlerts = filterAssetAlerts;
                                        newAsset = this.getFilteredAsset(newAsset, newAssetAlerts);
                                    }
                                    assetList[this.state.isSelected] = newAsset;
                                    if (!newAssetAlerts.length || newAssetAlerts == null)
                                        newAssetAlerts = [];
                                    alertsByAssetDict[assetId] = newAssetAlerts;

                                    this.setState({
                                        assetList: assetList,
                                        alertsByAssetDict: alertsByAssetDict,
                                        assetAlertList: newAssetAlerts,
                                        currentAsset: newAsset,
                                        loading: false,
                                        alertTableLoading: false
                                    }, async () => {
                                        await AlertService.getescalatedalerts(assetId)
                                            .then(response => {
                                                if (this._isMounted) {
                                                    let newShowModals = (response.data !== undefined && response.data.triageAlertsAssets[0] !== undefined && response.data.triageAlertsAssets[0].alertTypes !== undefined) ? new Array(response.data.triageAlertsAssets[0].alertTypes.length).fill(false) : [];
                                                    this.setState({
                                                        escalatedAlertsObj: response.data,
                                                        showModals: newShowModals, /*new Array(response.data?.triageAlertsAssets[0]?.alertTypes?.length).fill(false)*/
                                                        escalatedAlertsLoading: false,
                                                        alertTableLoading: false
                                                    });
                                                }
                                            })
                                            .catch(e => {
                                                console.log(e);
                                                this.setState({ currentAssetInfo: jsAsset, escalatedAlertsLoading: false, alertTableLoading: false });
                                            });
                                    });
                                }
                            }
                            else {

                                this.getRefreshAlerts(this.state.triagedAlertsList.map(t => t.alertId.toString()));
                                /*
                                delete alertsByAssetDict[assetId];
                                assetList = assetList.filter(a => a !== assetList[this.state.isSelected]);

                                this.setState({
                                    assetList: assetList,
                                    alertsByAssetDict: alertsByAssetDict,
                                    assetAlertList: [],
                                    currentAsset: {},
                                    //isSelected: -1,
                                    //assetSelected: false,
                                    loading: false,
                                    alertTableLoading: false
                                }, async () => {
                                        this.updateQueueCounts(this.state.currentRadio, this.state.assetList);
                                        await AlertService.finishasset(this.state.currentQueueAsset)
                                            .then(response2 => {
                                                this.setState({
                                                    currentQueueAsset: {}
                                                }, async () => {
                                                        let triageInfo = this.state.radioDict[this.state.currentRadio];
                                                        let checkoutTime = moment().format();
                                                        this.updateQueueCounts(this.state.currentRadio, this.state.assetList);

                                                        await AlertService.getuntriagedqueueasset(this.state.triageSession.userId, checkoutTime, this.state.assetList)
                                                            .then(response3 => {
                                                                if (this._isMounted) {
                                                                    let queueItem = response3.data;
                                                                    let queueIndex = assetList.length ? assetList.findIndex(a => a.assetId == queueItem.assetId) : -1;
                                                                    
                                                                    this.setState({
                                                                        currentQueueAsset: queueItem,
                                                                        currentQueueIndex: queueIndex
                                                                    }, () => {
                                                                            if (this.state.currentQueueIndex >= 0)
                                                                                this.setState({
                                                                                    assetSelected: true,
                                                                                    isSelected: queueIndex
                                                                                }, () => {
                                                                                    this.onAlertAssetClick(queueIndex);
                                                                                });
                                                                            else 
                                                                                this.setState({
                                                                                    assetSelected: false,
                                                                                    isSelected: -1,
                                                                                    loading: false
                                                                                });
                                                                    });
                                                                }
                                                            })
                                                            .catch(e => {
                                                                console.log(e);
                                                            })
                                                })
                                            })
                                            .catch(e => {
                                                console.log(e);
                                            })
                                });*/
                            }
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({ loading: false, alertTableLoading: false });
                    });
        })
    }
    
    getFilteredAsset(newAsset, newAssetAlerts) {
        if (newAssetAlerts.length === 0) {
            newAsset.alertCount = 0;
            newAsset.alertTypes = [];
            newAsset.alertTypeIds = [];
            newAsset.alertTypesStr = "";
        }
        else {
            newAsset.alertCount = newAssetAlerts.length;
            let assetAlertTypes = newAssetAlerts.filter((item, index, array) => array.findIndex(n => n.typeId === item.typeId) === index);
            
            newAsset.alertTypes = assetAlertTypes.map(a => a.type);
            newAsset.alertTypeIds = assetAlertTypes.map(a => a.typeId);
            newAsset.alertTypesStr = newAsset.alertTypes.join(", ");
        }
        
        return newAsset;
    }

    changeRadio(e) {
        const x = e.target.value;
        Event("Lifewhere Triage radio clicked", "User clicked a radio in LifeWhere Triage Alerts", "Lifewhere Triage radio clicked");

        this.setState({ currentRadio: x }, () => {
            if (this.state.radioDict[this.state.currentRadio] !== undefined && this.state.radioDict[this.state.currentRadio] !== null)
                this.setState({
                    assetList: this.state.radioDict[this.state.currentRadio].triageAlertsAssets,
                    alertsByAssetDict: this.state.radioDict[this.state.currentRadio].alertsByAssetDict
                });
            else
                this.setState({ assetList: [], alertsByAssetDict: {} });
        });
    }

    handleCheckbox(e) {
        const checkedVal = e.target.value;

        if (e.target.checked) {
            this.setState(prevState => ({
                visibility: {
                    ...prevState.visibility,
                    [checkedVal]: true
                }
            }));
        }
        else {
            this.setState(prevState => ({
                visibility: {
                    ...prevState.visibility,
                    [checkedVal]: false
                }
            }));
        }
    }

   
    onBackClick() {
        Event("Lifewhere Triage back button clicked", "User clicked back button in asset screen of lifewhere triage alerts", "Lifewhere Triage back button clicked");
        this.setState({
            sessionStarted: false, startSessionLoading: false,
            isSelected: -1, assetSelected: false, currentAsset: {}, assetAlertList: [], typeSelected: -1, alertTypeSelected: false,
            currentType: "", typeAlertList: [], falsePosAlertsList: {}, selectedAlerts: [], dataList: [], chartDataList: [], rawDataList: [], last150DataList: [], longAlertList: [], allSelected: false,
            currentCustomer: alertCustomer, showCustomerInfo: false, currentAssetInfo: jsAsset, showAssetInfo: false, showEscalatedAlerts: false, graphedAlerts: [], allGraphed: false, showAllAlerts: false,
            showSkips: false, showCheckouts: false, clearedSkips: false, clearedSkipsSuccess: false, clearedCheckouts: false, clearedCheckoutsSuccess: false
        }, async () => {
                await triagecheckoutService.finishasset(this.state.currentQueueAsset)
                    .then(response => {
                        this.setState({ currentQueueAsset: {}, currentQueueIndex: -1 }, () => {
                            this.publishSession();
                        });
                    })
                    .catch(e => {
                        console.log(e);
                    })
        });
    }

    async onCustomerInfoClick() {
        Event("View customer info clicked", "User clicked to view customer info in Lifewhere Triage Alerts", "View customer info clicked");
        this.setState({ showCustomerInfo: !this.state.showCustomerInfo }, async () => {
            if (this.state.currentCustomer === alertCustomer) {
                this.setState({ customerLoading: true }, async () => {
                    await AlertService.get(this.state.assetAlertList[0].organization)
                        .then(response => {
                            if (this._isMounted)
                                this.setState({
                                    currentCustomer: response.data
                                }, () => {
                                        this.getCustomerPartner(this.state.currentCustomer.parentOrganizationId);
                                });
                        })
                        .catch(e => {
                            console.log(e);
                        });
                });
            }
                
        });
    }

    getCustomerPartner(id) {
        const x = this.props.partners.find(p => p.organizationId === id);
        let partnerStr = "";

        if (x !== undefined && x != null && x.company !== null) {
            partnerStr = x.company + " (" + x.name + ")";
        }
        else {
            partnerStr = "Name unavailable for PartnerID  " + id; /* todo fetch info? */
        }

        this.setState({ currentPartnerName: partnerStr, customerLoading: false });
    }

    async onAssetInfoClick() {
        Event("View asset info clicked", "User clicked to view asset info in Lifewhere Triage Alerts", "View asset info clicked");
        this.setState({ showAssetInfo: !this.state.showAssetInfo }, async () => {
            if (this.state.currentAssetInfo === jsAsset) {
                /*this.setState({ assetLoading: true }, async () => {
                    await AlertService.getalertasset(this.state.assetAlertList[0]) 
                        .then(response => {
                            if (this._isMounted)
                                this.setState({
                                    currentAssetInfo: response.data, assetLoading: false
                                });
                        })
                        .catch(e => {
                            console.log(e);
                            this.setState({ currentAssetInfo: jsAsset, assetLoading: false });
                        });
                });*/
            }

        });
    }

    async onEscalatedAlertsClick() {
        this.setState({ showEscalatedAlerts: !this.state.showEscalatedAlerts });
    }

    getSeriesMax(arr) {
        let len = arr.length;
        let max = -Infinity;

        while (len--) {
            max = arr[len] > max ? arr[len] : max;
        }
        return max;
    }

    onViewAllAlertsClick() {
        Event("View all alerts clicked", "User clicked to view all alerts in LifeWhere Triage Alerts", "View all alerts clicked");
        this.setState({ showAllAlerts: !this.state.showAllAlerts }, () => {
            if (this.state.showAllAlerts) {
                this.setState({
                    alertTableLoading: true,
                    selectedAlerts: [],
                    graphedAlerts: [],
                    dataMax: 0,
                    rawMax: 0,
                    currentMax: 0,
                    currentType: 'ALL',
                    typeAlertList: this.state.assetAlertList
                }, async () => {
                        await AlertService.getalertlistdata(this.state.currentAsset.assetId, true, this.state.typeAlertList)
                            .then(response => {
                                if (this._isMounted)
                                    this.setState({
                                        alertData: response.data,
                                        dataList: [],
                                        chartDataList: [],
                                        rawDataList: [],
                                        last150DataList: [],
                                        longAlertList: [],
                                        selectedTypeId: -1,
                                        alertTypeSelected: true,
                                        //alertTableLoading: false,
                                        selectedAlerts: [],
                                        allSelected: false,
                                        graphedAlerts: [],
                                        allGraphed: false
                                    }, () => {
                                            if (this.state.typeAlertList.length < 20) {
                                                let graphedAlerts = [];
                                                let selectedAlerts = this.state.typeAlertList;
                                                let dataList = [];
                                                let chartDataList = [];
                                                let rawDataList = [];
                                                let last150DataList = [];
                                                let dataMax = 0;
                                                let rawMax = 0;
                                                let currentMax = 0;

                                                for (let i = 0; i < this.state.typeAlertList.length; i++) {
                                                    const alert = this.state.typeAlertList[i];
                                                    const series = this.state.alertData[alert.alertId];

                                                    let seriesRuns = series !== null ? series.series : []; //series?.series; //breakAlertData(series.data, series.offHigh, series.fanOnlyLow, series.fanOnlyHigh, series.fanOnly);  
                                                    series.series = seriesRuns;

                                                    if (series !== undefined && (parseInt(alert.duration, 10) < 720 || this.state.longAlertList.some(a => parseInt(a.alertId) === parseInt(alert.alertId)))) { /* is long alert or has already been loaded */
                                                        graphedAlerts = graphedAlerts.concat(alert);
                                                        dataList = dataList.concat(series);
                                                        if (series !== undefined) {
                                                            for (let i = 0; i < seriesRuns.length; i++) {
                                                                if (seriesRuns[i].length > dataMax)
                                                                    dataMax = seriesRuns[i].length;

                                                                const seriesMax = this.getSeriesMax(seriesRuns[i]);//Math.max(...seriesRuns[i].map(point => point.value));
                                                                if (seriesMax > currentMax) {
                                                                    console.log("currentMax: " + seriesMax);
                                                                    currentMax = seriesMax;
                                                                }
                                                            }
                                                            if (series.dataMax > rawMax)
                                                                rawMax = series.dataMax;

                                                            for (let j = 0; j < seriesRuns.length; j++) {
                                                                let runNum = j + 1;
                                                                let dataArr = seriesRuns[j].map((item) => (item.value));
                                                                let lastArr = [...dataArr].slice(dataArr.length >= 151 ? dataArr.length - 151 : 0);

                                                                const chartData = {
                                                                    name: `${alert.alertId} (${runNum})`,
                                                                    data: dataArr,
                                                                    color: series.color,
                                                                    dashStyle: 'solid',
                                                                    type: 'line',
                                                                    opacity: 1,
                                                                    marker: {
                                                                        enabledThreshold: 0
                                                                    },
                                                                    yAxis: 0
                                                                };
                                                                chartDataList = chartDataList.concat(chartData);

                                                                const lastData = {
                                                                    name: chartData.name,
                                                                    data: lastArr,
                                                                    color: series.color,
                                                                    dashStyle: 'solid',
                                                                    type: 'line',
                                                                    opacity: 1,
                                                                    marker: {
                                                                        enabledThreshold: 0
                                                                    },
                                                                    yAxis: 0
                                                                };
                                                                last150DataList = last150DataList.concat(lastData);

                                                                if (this.state.currentRadio === "AirConditioner") {
                                                                    let furnArr = seriesRuns[j].map((item) => (item.furnaceValue));
                                                                    let lastFurnArr = [...furnArr].slice(furnArr.length >= 151 ? furnArr.length - 151 : 0);//.reverse();

                                                                    const furnData = {
                                                                        name: chartData.name + " Furnace",
                                                                        data: furnArr,
                                                                        color: series.color,
                                                                        dashStyle: 'longDash',
                                                                        type: 'line',
                                                                        opacity: 1,
                                                                        marker: {
                                                                            enabledThreshold: 0
                                                                        },
                                                                        yAxis: 0
                                                                    };
                                                                    chartDataList = chartDataList.concat(furnData);

                                                                    const lastFurnData = {
                                                                        name: furnData.name,
                                                                        data: lastFurnArr,
                                                                        color: series.color,
                                                                        dashStyle: 'longDash',
                                                                        type: 'line',
                                                                        opacity: 1,
                                                                        marker: {
                                                                            enabledThreshold: 0
                                                                        },
                                                                        yAxis: 0
                                                                    };
                                                                    last150DataList = last150DataList.concat(lastFurnData);
                                                                }
                                                            }

                                                        }
                                                    }
                                                }

                                                this.setState({
                                                    alertTableLoading: false,
                                                    graphedAlerts: graphedAlerts,
                                                    allGraphed: true,
                                                    selectedAlerts: selectedAlerts,
                                                    allSelected: true,
                                                    dataList: dataList,
                                                    chartDataList: chartDataList,
                                                    rawDataList: rawDataList,
                                                    last150DataList: last150DataList,
                                                    dataMax: dataMax,
                                                    rawMax: rawMax,
                                                    currentMax: currentMax
                                                }, () => {
                                                    if (this.state.currentRadio === "AirConditioner" && this.state.dataList.length) {
                                                        let rlaVal = this.state.dataList[0].rla;
                                                        let rlaMin = rlaVal - 5;
                                                        if (currentMax >= rlaMin) {
                                                            this.setState(prevState => ({
                                                                visibility: {
                                                                    ...prevState.visibility,
                                                                    rla: true
                                                                }
                                                            }));
                                                        }
                                                    }
                                                });
                                            }
                                            else {
                                                this.setState({
                                                    alertTableLoading: false

                                                });
                                            }
                                    });
                            })
                            .catch(e => {
                                console.log(e);
                            });
                });
            }
            else {
                this.setState({
                    alertTypeSelected: false,
                    currentType: '',
                    selectedAlerts: [],
                    graphedAlerts: [],
                    allSelected: false,
                    allGraphed: false,
                    dataList: [],
                    alertData: {},
                    chartDataList: [],
                    rawDataList: [],
                    last150DataList: [],
                    longAlertList: []
                })
            }
        });
    }

    async onStartSessionClick(sortByAlertCount = true) {
        Event("Start Session clicked", "User clicked to start LifeWhere Triage Alerts session", "Start Session clicked");
        this.setState({
            sessionStarted: true,
            startSessionLoading: true,
            openStartMenu: false
        }, async () => {
                this.createSession(sortByAlertCount);
        })
    }

    onSkipAssetClick() {
        Event("Skip Asset clicked", "User skipped asset in LifeWhere Triage Alerts", "Skip Asset clicked");
        let skipTime = moment().format();

        this.setState({
            isSelected: -1, /*assetSelected: false,*/ currentAsset: {}, assetAlertList: [],
            typeSelected: -1, alertTypeSelected: false,
            currentType: "", typeAlertList: [], falsePosAlertsList: {}, selectedAlerts: [], dataList: [], chartDataList: [], rawDataList: [], last150DataList: [], longAlertList: [], allSelected: false,
            currentCustomer: alertCustomer, showCustomerInfo: false, currentAssetInfo: jsAsset, showAssetInfo: false, showEscalatedAlerts: false, graphedAlerts: [], allGraphed: false, showAllAlerts: false,
            loading: true
        }, async () => {
            let triageSkip = {
                assetId: this.state.currentQueueAsset.assetId,
                skipDateTime: skipTime,
                userId: this.state.currentQueueAsset.userId
            };
            await triagecheckoutService.skipasset(triageSkip)
                .then(response => {
                    if (response !== undefined && response !== null && response !== false/*response.status === 200*/)
                        this.setState({ message: "Successfully skipped asset" }, async () => {
                            let triageInfo = this.sortTriageAlertAssets(this.state.sortByAlertCount);
                            let checkoutTime = moment().format();
                            await triagecheckoutService.finishasset(this.state.currentQueueAsset)
                                .then(async response4 => {
                                    if (response4)
                                        await triagecheckoutService.getuntriagedqueueasset(this.state.triageSession.userId, checkoutTime, triageInfo.triageAlertsAssets)
                                            .then(response2 => {
                                                if (this._isMounted) {
                                                    let queueItem = response2;
                                                    let queueIndex = triageInfo.triageAlertsAssets.findIndex(a => parseInt(a.assetId) === parseInt(queueItem.assetId));

                                                    this.setState({
                                                        currentQueueAsset: queueItem,
                                                        currentQueueIndex: queueIndex,
                                                        loading: false
                                                    }, () => {
                                                        if (this.state.currentQueueIndex >= 0)
                                                            this.onAlertAssetClick(queueIndex);
                                                        else
                                                            this.setState({ assetSelected: false });
                                                    });
                                                }
                                            })
                                            .catch(e => {
                                                console.log(e);
                                                this.setState({ loading: false });
                                            });
                                })
                                .catch(e => {
                                    console.log(e);
                                });

                            
                        });
                    else
                        this.setState({ message: "Failed to skip asset." }, async () => {
                            let triageInfo = this.sortTriageAlertAssets(this.state.sortByAlertCount);
                            let checkoutTime = moment().format();
                            await triagecheckoutService.finishasset(this.state.currentQueueAsset)
                                .then(async response4 => {
                                    if (response4.status === 200)
                                        await triagecheckoutService.getuntriagedqueueasset(this.state.triageSession.userId, checkoutTime, triageInfo.triageAlertsAssets)
                                            .then(response2 => {
                                                if (this._isMounted) {
                                                    let queueItem = response2;
                                                    let queueIndex = triageInfo.triageAlertsAssets.findIndex(a => parseInt(a.assetId) === parseInt(queueItem.assetId));

                                                    this.setState({
                                                        currentQueueAsset: queueItem,
                                                        currentQueueIndex: queueIndex,
                                                        loading: false
                                                    }, () => {
                                                        if (this.state.currentQueueIndex >= 0)
                                                            this.onAlertAssetClick(queueIndex);
                                                        else
                                                            this.setState({ assetSelected: false });
                                                    });
                                                }
                                            })
                                            .catch(e => {
                                                console.log(e);
                                                this.setState({ loading: false });
                                            });
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                        });

                    setTimeout(() => {
                        this.setState({
                            message: ""
                        });
                    }, 3000);
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ loading: false });
                });
        });
    }

    async onAlertAssetClick(index) {
        let x = index;

        if (x < this.state.assetList.length) {
            let assetId = this.state.assetList[x].assetId;

            this.setState({
                currentAsset: this.state.assetList[x],
                falsePosAlertsList: {},
                assetAlertList: this.state.alertsByAssetDict[assetId],
                isSelected: x,
                assetSelected: true,
                escalatedAlertsLoading: true,
                alertTableLoading: true
            }, async () => {
                await AlertService.getalertasset(this.state.assetAlertList[0])
                    .then(response => {
                        if (this._isMounted) {
                            this.setState({
                                currentAssetInfo: response.data,
                                assetLoading: false
                            }, async () => {
                                await AlertService.getescalatedalerts(this.state.currentAsset.assetId)
                                    .then(response => {
                                        if (this._isMounted) {
                                            let newShowModals = (response.data !== undefined && response.data.triageAlertsAssets[0] !== undefined && response.data.triageAlertsAssets[0].alertTypes !== undefined) ? new Array(response.data.triageAlertsAssets[0].alertTypes.length).fill(false) : [];
                                            this.setState({
                                                escalatedAlertsObj: response.data,
                                                showModals: newShowModals, /*new Array(response.data?.triageAlertsAssets[0]?.alertTypes?.length).fill(false)*/
                                                escalatedAlertsLoading: false,
                                                alertTableLoading: false
                                            });
                                        }
                                    })
                                    .catch(e => {
                                        console.log(e);
                                        this.setState({ currentAssetInfo: jsAsset, escalatedAlertsLoading: false, alertTableLoading: false });
                                    });
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({
                            currentAssetInfo: jsAsset,
                            assetLoading: false,
                            alertTableLoading: false,
                            escalatedAlertsLoading: false
                        });
                    });
            });
        }
        else {
            this.setState({
                assetSelected: false,
                isSelected: -1,
            })
        }
    } 

    async onAlertTypeClick(index) {
        let x = index;
        let type = this.state.currentAsset.alertTypes[x];
        //let typeId = this.state.currentAsset.alertTypeIds[x];
        let assetAlerts = this.state.assetAlertList;
        this.setState({
            alertTableLoading: true,
            currentType: type,
            typeAlertList: assetAlerts.filter(a => a.type === type),
            typeSelected: x,
            selectedAlerts: [],
            graphedAlerts: [],
            dataMax: 0,
            rawMax: 0,
            currentMax: 0
        }, async () => {
            await AlertService.getalertlistdata(this.state.currentAsset.assetId, true, this.state.typeAlertList).then(response => {
                if (this._isMounted) {
                    this.setState({
                        alertData: response.data,
                        dataList: [],
                        chartDataList: [],
                        rawDataList: [],
                        last150DataList: [],
                        longAlertList: [],
                        selectedTypeId: this.state.typeAlertList[0].typeId,
                        alertTypeSelected: true,
                        //alertTableLoading: false,
                        selectedAlerts: [],
                        allSelected: false,
                        graphedAlerts: [],
                        allGraphed: false
                    }, () => {
                            if (this.state.typeAlertList.length < 20) {
                                let graphedAlerts = [];
                                let selectedAlerts = this.state.typeAlertList;
                                let dataList = [];
                                let chartDataList = [];
                                let rawDataList = [];
                                let last150DataList = [];
                                //let lastIgnitionDataList = [];
                                let dataMax = 0;
                                let rawMax = 0;
                                let currentMax = 0;
                                for (let i = 0; i < this.state.typeAlertList.length; i++) {
                                    const alert = this.state.typeAlertList[i];
                                    const series = this.state.alertData[alert.alertId];

                                    if (series !== undefined) {
                                        let seriesRuns = series.series; //breakAlertData(series.data, series.offHigh, series.fanOnlyLow, series.fanOnlyHigh, series.fanOnly);
                                        series.series = seriesRuns;

                                        if ((parseInt(alert.duration, 10) < 720 || this.state.longAlertList.some(a => parseInt(a.alertId) === parseInt(alert.alertId)))) { /* is long alert or has already been loaded */
                                            graphedAlerts = graphedAlerts.concat(alert);
                                            dataList = dataList.concat(series);
                                            if (series !== undefined) {
                                                for (let i = 0; i < seriesRuns.length; i++) {
                                                    if (seriesRuns[i].length > dataMax)
                                                        dataMax = seriesRuns[i].length;

                                                    const seriesMax = this.getSeriesMax(seriesRuns[i]);//Math.max(...seriesRuns[i].map(point => point.value));

                                                    if (seriesMax > currentMax) {
                                                        currentMax = seriesMax;
                                                    }
                                                }
                                                if (parseInt(alert.duration, 10) * 60 < dataMax) {
                                                    dataMax = parseInt(alert.duration, 10) * 60;
                                                }
                                                if (series.dataMax > rawMax)
                                                    rawMax = series.dataMax;

                                                for (let j = 0; j < seriesRuns.length; j++) {
                                                    let runNum = j + 1;
                                                    let dataArr = seriesRuns[j].map((item) => (item.value));
                                                    let lastArr = [...dataArr].slice(dataArr.length >= 151 ? dataArr.length - 151 : 0);

                                                    const chartData = {
                                                        name: `${alert.alertId} (${runNum})`,
                                                        data: dataArr,
                                                        color: series.color,
                                                        dashStyle: 'solid',
                                                        type: 'line',
                                                        opacity: 1,
                                                        marker: {
                                                            enabledThreshold: 0
                                                        },
                                                        yAxis: 0
                                                    };
                                                    chartDataList = chartDataList.concat(chartData);

                                                    const lastData = {
                                                        name: chartData.name,
                                                        data: lastArr,
                                                        color: series.color,
                                                        dashStyle: 'solid',
                                                        type: 'line',
                                                        opacity: 1,
                                                        marker: {
                                                            enabledThreshold: 0
                                                        },
                                                        yAxis: 0
                                                    };
                                                    last150DataList = last150DataList.concat(lastData);

                                                    if (this.state.currentRadio === "AirConditioner") {
                                                        let furnArr = seriesRuns[j].map((item) => (item.furnaceValue));
                                                        let lastFurnArr = [...furnArr].slice(furnArr.length >= 151 ? furnArr.length - 151 : 0);//.reverse();

                                                        const furnData = {
                                                            name: chartData.name + " Furnace",
                                                            data: furnArr,
                                                            color: series.color,
                                                            dashStyle: 'longDash',
                                                            type: 'line',
                                                            opacity: 1,
                                                            marker: {
                                                                enabledThreshold: 0
                                                            },
                                                            yAxis: 0
                                                        };
                                                        chartDataList = chartDataList.concat(furnData);

                                                        const lastFurnData = {
                                                            name: furnData.name,
                                                            data: lastFurnArr,
                                                            color: series.color,
                                                            dashStyle: 'longDash',
                                                            type: 'line',
                                                            opacity: 1,
                                                            marker: {
                                                                enabledThreshold: 0
                                                            },
                                                            yAxis: 0
                                                        };
                                                        last150DataList = last150DataList.concat(lastFurnData);
                                                    }
                                                }

                                            }
                                        }
                                    }
                                }

                                 
                                this.setState({
                                    alertTableLoading: false,
                                    graphedAlerts: graphedAlerts,
                                    allGraphed: true,
                                    selectedAlerts: selectedAlerts,
                                    allSelected: true,
                                    dataList: dataList,
                                    chartDataList: chartDataList,
                                    rawDataList: rawDataList,
                                    last150DataList: last150DataList,
                                    dataMax: dataMax,
                                    rawMax: rawMax,
                                   currentMax: currentMax
                                }, () => {
                                    if (this.state.dataList.length) {
                                        let rlaVal = this.state.dataList[0].rla;
                                        let rlaMin = rlaVal - 5;
                                        if (currentMax >= rlaMin) {
                                            this.setState(prevState => ({
                                                visibility: {
                                                    ...prevState.visibility,
                                                    rla: true
                                                }
                                            }));
                                        }
                                    }
                                });
                            }
                            else {
                                this.setState({
                                    alertTableLoading: false
                                    
                                });                       
                            }

                            
                    });
                }
            })
            .catch(e => {
                console.log(e);
            });
        });
    }

    async onGraphAll() {
        Event("Graph All clicked", "User clicked graph all in LifeWhere Triage Alerts", "Graph All clicked");
        let graphedAlerts = [];
        let selectedAlerts = [];
        let dataList = [];
        let chartDataList = [];
        let rawDataList = [];
        let last150DataList = [];
        let dataMax = 0;
        let rawMax = 0;
        let currentMax = 0;

        this.setState({ allGraphed: !this.state.allGraphed }, () => {
            if (this.state.allGraphed) {
                selectedAlerts = this.state.typeAlertList;
                this.setState({ loading: true }, async () => {
                    await AlertService.getalertlistdata(this.state.currentAsset.assetId, false, this.state.typeAlertList)
                        .then(response => {
                            if (this._isMounted)
                                this.setState({
                                    alertData: response.data
                                }, () => {
                                    for (let i = 0; i < this.state.typeAlertList.length; i++) {
                                        const alert = this.state.typeAlertList[i];
                                        let series = this.state.alertData[alert.alertId];

                                        let seriesRuns = series !== undefined && series !== null ? series.series : []; //breakAlertData(series.data, series.offHigh, series.fanOnlyLow, series.fanOnlyHigh, series.fanOnly);
                                        if (series === undefined || series === null)
                                            series = {};
                                        series.series = seriesRuns;

                                        if (series !== undefined && (parseInt(alert.duration, 10) < 720 || this.state.longAlertList.some(a => parseInt(a.alertId) === parseInt(alert.alertId)))) { /* is long alert or has already been loaded */
                                            graphedAlerts = graphedAlerts.concat(alert);
                                            dataList = dataList.concat(series);
                                            if (series !== undefined) {
                                                for (let i = 0; i < seriesRuns.length; i++) {
                                                    if (seriesRuns[i].length > dataMax)
                                                        dataMax = seriesRuns[i].length;

                                                    const seriesMax = this.getSeriesMax(seriesRuns[i]);//Math.max(...seriesRuns[i].map(point => point.value));
                                                    if (seriesMax > currentMax) {
                                                        currentMax = seriesMax;
                                                    }
                                                }
                                                if (series.dataMax > rawMax)
                                                    rawMax = series.dataMax;

                                                for (let j = 0; j < seriesRuns.length; j++) {
                                                    let runNum = j + 1;
                                                    let dataArr = seriesRuns[j].map((item) => (item.value));
                                                    let lastArr = [...dataArr].slice(dataArr.length >= 151 ? dataArr.length - 151 : 0);//.reverse(); 
                                                    //console.log("lastArr.length: " + lastArr.lenth);

                                                    const chartData = {
                                                        name: `${alert.alertId} (${runNum})`,
                                                        data: dataArr,
                                                        color: series.color,
                                                        dashStyle: 'solid',
                                                        type: 'line',
                                                        opacity: 1,
                                                        marker: {
                                                            enabledThreshold: 0
                                                        },
                                                        yAxis: 0
                                                    };
                                                    chartDataList = chartDataList.concat(chartData);

                                                    const lastData = {
                                                        name: chartData.name,
                                                        data: lastArr,
                                                        color: series.color,
                                                        dashStyle: 'solid',
                                                        type: 'line',
                                                        opacity: 1,
                                                        marker: {
                                                            enabledThreshold: 0
                                                        },
                                                        yAxis: 0
                                                    };
                                                    last150DataList = last150DataList.concat(lastData);

                                                    if (this.state.currentRadio === "AirConditioner") {
                                                        let furnArr = seriesRuns[j].map((item) => (item.furnaceValue));
                                                        let lastFurnArr = [...furnArr].slice(furnArr.length >= 151 ? furnArr.length - 151 : 0);//.reverse();

                                                        const furnData = {
                                                            name: chartData.name + " Furnace",
                                                            data: furnArr,
                                                            color: series.color,
                                                            dashStyle: 'longDash',
                                                            type: 'line',
                                                            opacity: 1,
                                                            marker: {
                                                                enabledThreshold: 0
                                                            },
                                                            yAxis: 0
                                                        };
                                                        chartDataList = chartDataList.concat(furnData);

                                                        const lastFurnData = {
                                                            name: furnData.name,
                                                            data: lastFurnArr,
                                                            color: series.color,
                                                            dashStyle: 'longDash',
                                                            type: 'line',
                                                            opacity: 1,
                                                            marker: {
                                                                enabledThreshold: 0
                                                            },
                                                            yAxis: 0
                                                        };
                                                        last150DataList = last150DataList.concat(lastFurnData);
                                                    }
                                                }

                                            }
                                        }
                                    }

                                    this.setState({
                                        loading: false,
                                        graphedAlerts: graphedAlerts,
                                        selectedAlerts: selectedAlerts,
                                        dataList: dataList,
                                        chartDataList: chartDataList,
                                        rawDataList: rawDataList,
                                        last150DataList: last150DataList,
                                        dataMax: dataMax,
                                        rawMax: rawMax,
                                        currentMax: currentMax
                                    }, () => {
                                        if (this.state.currentRadio === "AirConditioner" && this.state.dataList.length) {
                                            let rlaVal = this.state.dataList[0].rla;
                                            let rlaMin = rlaVal - 5;
                                            if (currentMax >= rlaMin) {
                                                this.setState(prevState => ({
                                                    visibility: {
                                                        ...prevState.visibility,
                                                        rla: true
                                                    }
                                                }));
                                            }
                                        }
                                    });
                                });
                        })
                        .catch(e => {
                            console.log(e);
                            this.setState({ loading: false, message: "Failed to fetch graph data for all alerts" });

                            setTimeout(() => {
                                this.setState({
                                    message: ""
                                });
                            }, 3000);
                        });
                });

            }
            else {
                selectedAlerts = this.state.selectedAlerts;

                this.setState({ graphedAlerts: graphedAlerts, selectedAlerts: selectedAlerts, dataList: dataList, dataMax: dataMax, rawMax: rawMax, currentMax: currentMax, chartDataList: chartDataList, rawDataList: rawDataList, last150DataList: last150DataList });
            }
        });
    }

    async handleGraphAlertCheck(e) {
        const checkedVal = e.target.value;
        const alert = this.state.typeAlertList.find(t => t.alertId === checkedVal);
        const graphAlertId = alert.alertId;

        let graphedAlerts = this.state.graphedAlerts;
        let selectedAlerts = this.state.selectedAlerts;
        let alertData = this.state.alertData;
        let dataList = this.state.dataList;
        let longAlertList = this.state.longAlertList;
        let chartDataList = this.state.chartDataList;
        let rawDataList = this.state.rawDataList;
        let last150DataList = this.state.last150DataList;

        let list = [];
        list = list.concat(alert);

        if (e.target.checked) {
            this.setState({ loading: true }, async () => {
                await AlertService.getalertlistdata(this.state.currentAsset.assetId, false, list)
                    .then(response => {
                        if (this._isMounted) {
                            let longData = response.data;
                            if (longData[graphAlertId] === undefined || longData[graphAlertId] === null) {
                                longData[graphAlertId] = { color: '', series: [] };
                            }
                            longData[graphAlertId].color = alertData[graphAlertId] !== undefined && alertData[graphAlertId] !== null ? alertData[graphAlertId].color : 'white';
                            alertData[graphAlertId] = longData[graphAlertId];

                            let series = longData[graphAlertId];
                            longAlertList = longAlertList.concat(alert);
                            graphedAlerts = graphedAlerts.concat(alert);
                            selectedAlerts = selectedAlerts.concat(alert);
                            dataList = dataList.concat(series);

                            let seriesRuns = series.series; //breakAlertData(series.data, series.offHigh, series.fanOnlyLow, series.fanOnlyHigh, series.fanOnly);
                            series.series = seriesRuns;

                            let seriesMax = 0;
                            let currentMax = 0;
                            if (series !== undefined) {
                                for (var i = 0; i < seriesRuns.length; i++) { //series.series.length; i++) {
                                    let thisMax = seriesRuns[i].length;//.series[i].length;
                                    if (thisMax > seriesMax)
                                        seriesMax = thisMax;

                                    const seriesMaxCurrent = this.getSeriesMax(seriesRuns[i]);//Math.max(...seriesRuns[i].map(point => point.value));
                                    if (seriesMaxCurrent > currentMax) {
                                        currentMax = seriesMaxCurrent;
                                    }
                                }

                                for (let j = 0; j < seriesRuns.length; j++) {
                                    let runNum = j + 1;
                                    let dataArr = seriesRuns[j].map((item) => (item.value));
                                    let lastArr = [...dataArr].slice(dataArr.length >= 151 ? dataArr.length - 151 : 0);//.reverse();

                                    const chartData = {
                                        name: `${alert.alertId} (${runNum})`,
                                        data: dataArr,
                                        color: series.color,
                                        dashStyle: 'solid',
                                        type: 'line',
                                        marker: {
                                            enabledThreshold: 0
                                        },
                                        yAxis: 0
                                    };
                                    chartDataList = chartDataList.concat(chartData);

                                    const lastData = {
                                        name: chartData.name,
                                        data: lastArr, 
                                        color: series.color,
                                        dashStyle: 'solid',
                                        type: 'line',
                                        marker: {
                                            enabledThreshold: 0
                                        },
                                        yAxis: 0
                                    };
                                    
                                    last150DataList = last150DataList.concat(lastData);

                                    if (this.state.currentRadio === "AirConditioner") {
                                        let furnArr = seriesRuns[j].map((item) => (item.furnaceValue));
                                        let lastFurnArr = [...furnArr].slice(furnArr.length >= 151 ? furnArr.length - 151 : 0);//.reverse();

                                        const furnData = {
                                            name: chartData.name + " Furnace",
                                            data: furnArr,
                                            color: series.color,
                                            dashStyle: 'longDash',
                                            type: 'line',
                                            marker: {
                                                enabledThreshold: 0
                                            },
                                            yAxis: 0
                                        };
                                        chartDataList = chartDataList.concat(furnData);

                                        const lastFurnData = {
                                            name: furnData.name,
                                            data: lastFurnArr,
                                            color: series.color,
                                            dashStyle: 'longDash',
                                            type: 'line',
                                            yAxis: 0
                                        };
                                        
                                        last150DataList = last150DataList.concat(lastFurnData);
                                    }
                                }

                            }

                            let dataMax = this.state.dataMax;
                            let rawMax = this.state.rawMax;
                            let currentMaxSt = this.state.currentMax;
                            if (seriesMax > dataMax)
                                dataMax = seriesMax;
                            if (series.dataMax > rawMax)
                                rawMax = series.dataMax;
                            if (currentMax > currentMaxSt)
                                currentMaxSt = currentMax;

                            this.setState({
                                loading: false,
                                longAlertList: longAlertList,
                                graphedAlerts: graphedAlerts,
                                selectedAlerts: selectedAlerts,
                                dataList: dataList,
                                dataMax: dataMax,
                                rawMax: rawMax,
                                currentMax: currentMaxSt,
                                chartDataList: chartDataList,
                                rawDataList: rawDataList,
                                last150DataList: last150DataList
                            }, () => {
                                if (this.state.currentRadio === "AirConditioner" && this.state.dataList.length) {
                                    let rlaVal = this.state.dataList[0].rla;
                                    let rlaMin = rlaVal - 5;
                                    if (currentMax >= rlaMin) {
                                        this.setState(prevState => ({
                                            visibility: {
                                                ...prevState.visibility,
                                                rla: true
                                            }
                                        }));
                                    }
                                }
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({ loading: false, message: "Failed to load graph data for selected alert!" });

                        setTimeout(() => {
                            this.setState({
                                message: ""
                            });
                        }, 3000);
                    });
            })
        }
        else {
            longAlertList = longAlertList.filter(a => a.alertId !== checkedVal);
            graphedAlerts = graphedAlerts.filter(a => a.alertId !== checkedVal);
            dataList = dataList.filter(d => d.name !== checkedVal);
            chartDataList = chartDataList.filter(d => !d.name.includes(checkedVal));
            rawDataList = rawDataList.filter(d => !d.name.includes(checkedVal));
            last150DataList = last150DataList.filter(d => !d.name.includes(checkedVal));

            this.setState({ longAlertList: longAlertList, graphedAlerts: graphedAlerts, dataList: dataList, chartDataList: chartDataList, rawDataList: rawDataList, last150DataList: last150DataList });
        }
    }

    async onSelectAll() {
        Event("Select All clicked", "User clicked select all in LifeWhere Triage Alerts", "Select All Clicked");
        let selectedAlerts = [];

        this.setState({ allSelected: !this.state.allSelected }, () => {
            if (this.state.allSelected)
                selectedAlerts = this.state.typeAlertList;

            this.setState({ selectedAlerts: selectedAlerts });
        });
    }

    async handleAlertCheck(e) {
        const checkedVal = e.target.value;
        const alert = this.state.typeAlertList.find(t => t.alertId === checkedVal);
        let selectedAlerts = this.state.selectedAlerts;

        if (e.target.checked) {
            selectedAlerts = selectedAlerts.concat(alert);
            this.setState({ selectedAlerts: selectedAlerts });
        }
        else {
            selectedAlerts = selectedAlerts.filter(s => s.alertId !== checkedVal);
            this.setState({ selectedAlerts: selectedAlerts });
        }
    }

    async handleLongAlertCheck(e) {
        const checkedVal = e.target.value;
        const alert = this.state.typeAlertList.find(t => t.alertId === checkedVal);
        
        this.setState({ currentLongAlert: alert }, () => {
            this.openLongAlertConfirmModal();
        });
    }

    handleColorClick = (alertId) => {
        this.setState({ selectedColor: alertId, showColorPicker: true });
    }

    handleColorChange = (color) => {
        Event("Alert color change", "User changed graph color of an alert in LifeWhere Triage Alerts", "Alert color change");
        const alertId = this.state.selectedColor;
        let alertData = this.state.alertData;
        let data = alertData[alertId];

        var colorObj = tinycolor(color.hex);
        //let colorBrightness = colorObj.getBrightness();
        let colorLightness = colorObj.toHsl().l;
        if (colorLightness > 0.5) { //colorBrightness > 180) { 
            this.setState({
                showMessage: true,
                message: "Selected color is too light, please select a darker color!"
            });

            setTimeout(() => {
                this.setState({
                    showMessage: false,
                    message: ""
                });
            }, 3000);
        }
        else {
            data.color = colorObj.toHslString(); //.hex;
            alertData[alertId] = data;

            let chartDataList = this.state.chartDataList;
            let colorDataList = chartDataList.filter(d => d.name.includes(alertId));

            for (let i = 0; i < colorDataList.length; i++) {
                let dataIndex = chartDataList.indexOf(colorDataList[i]);
                let newData = chartDataList[dataIndex];

                newData.color = colorObj.toHslString(); //.hex;
                chartDataList[dataIndex] = newData;
            }

            let rawDataList = this.state.rawDataList;
            let rawColorList = rawDataList.filter(d => d.name.includes(alertId));

            for (let j = 0; j < rawColorList.length; j++) {
                let rawIndex = rawDataList.indexOf(rawColorList[j]);
                let newRawData = rawDataList[rawIndex];

                newRawData.color = colorObj.toHslString(); //.hex;
                rawDataList[rawIndex] = newRawData;
            }

            let last150DataList = this.state.last150DataList;
            let lastColorList = last150DataList.filter(d => d.name.includes(alertId));

            for (let k = 0; k < lastColorList.length; k++) {
                let dataIndex = last150DataList.indexOf(lastColorList[k]);
                let newLastData = last150DataList[dataIndex];

                newLastData.color = colorObj.toHslString(); //.hex;
                last150DataList[dataIndex] = newLastData;
            }

            this.setState({ alertData: alertData, chartDataList: chartDataList, rawDataList: rawDataList, last150DataList: last150DataList });
        }
    }

    closeColorPicker() {
        this.setState({ selectedColor: -1, showColorPicker: false });
    }

    setLoading(isLoading) {
        this.setState({ loading: isLoading });
    }

/* START GRAPH BUTTON FUNCTIONS */

    async onRetrainClick(typeIds) {
        Event("Retrain asset clicked", "User clicked retrain in LifeWhere Triage Alerts asset page", "Retrain clicked");
        let fpList = this.state.falsePosAlertsList.filter(f => typeIds.some(t => t === f.id));
        let commentStr = "";

        if (fpList.length) {
            commentStr += "Retrain due to " + fpList[0].count + " False Positives of alert type " + fpList[0].type;

            if (fpList.length > 1) {
                for (var i = 1; i < fpList.length; i++) {
                    if (i + 1 === fpList.length) 
                        commentStr += " and ";
                    else 
                        commentStr += ", ";

                    commentStr += fpList[i].count + " False Positives of alert type " + fpList[i].type;
                }
            }

            commentStr += ".";
            console.log("comment: " + commentStr);
        }

        this.setState({ loading: true, showRetrainConfirm: false }, async () => {
            await AssetService.retrainasset(this.state.currentAsset.assetId, commentStr)
                .then(response => {
                    if (response.status === 200)
                        this.setState({ message: "Successfully set the asset to Retrain!", loading: false });
                    else
                        this.setState({ message: "Failed to set asset to Retrain.", loading: false });

                    setTimeout(() => {
                        this.setState({
                            message: ""
                        });
                    }, 3000);
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ message: "Failed to set asset to Retrain.", loading: false });

                    setTimeout(() => {
                        this.setState({
                            message: ""
                        });
                    }, 3000);
                });
        });
    }

    openRetrainConfirmModal() {
        let fpAlertsDict = this.state.falsePosAlertsList;
        const alertTypes = this.state.currentAsset.alertTypes;
        const alertTypeIds = this.state.currentAsset.alertTypeIds;

        if (fpAlertsDict.length) {
            // todo this for loop is causing console warning = "Function declared in a loop contains unsafe references to variable(s) 'typeIndex'   no-loop-func"
            for (var typeIndex = 0; typeIndex < alertTypes.length; typeIndex++) {
                if (!fpAlertsDict.some(a => a.type === alertTypes[typeIndex])) {
                    const type = alertTypes[typeIndex];
                    const id = alertTypeIds.length > typeIndex ? alertTypeIds[typeIndex] : 0;
                    const alertList = this.state.assetAlertList.filter(a => a.type === type);
                    var fpItem = {
                        id: id,
                        type: type,
                        count: alertList.length
                    }
                    fpAlertsDict = fpAlertsDict.concat(fpItem);
                }
            }
        }
        else {
            fpAlertsDict = [];
            // todo this for loop is causing console warning = "Function declared in a loop contains unsafe references to variable(s) 'typeIndex2'   no-loop-func"
            for (var typeIndex2 = 0; typeIndex2 < alertTypeIds.length; typeIndex2++) {
                var alertList = this.state.assetAlertList.filter(a => a.type === alertTypes[typeIndex2]);
                var fpItem2 = {
                    id: alertTypeIds[typeIndex2],
                    type: alertTypes[typeIndex2],
                    count: alertList.length
                }
                fpAlertsDict = fpAlertsDict.concat(fpItem2);
            }
        }

        this.setState({ falsePosAlertsList: fpAlertsDict }, () => {
            this.showRetrainConfirmModal();
        });
    }

    showRetrainConfirmModal() {
        this.setState({
            showRetrainConfirm: !this.state.showRetrainConfirm
        }, () => {
            if (this.state.showRetrainConfirm)
                ModalView('retrainAsset');
        })
    }

    onForceReloadClick() {
        Event("Force Reload Clicked", "User force reloaded all alerts for selected LifeWhere Triage Alerts asset", "Force Reload clicked");
        this.setState({ showForceReloadConfirm: false, loading: true }, () => {
            if (this.state.assetSelected)
                this.reloadAssetAlerts(this.state.currentAsset.assetId);
            else 
                this.getAlerts();
        });
    }

    openForceReloadConfirmModal() {
        this.setState({
            modalTitle: "Reload Alerts",
            modalMessage: "You are about to force reload all alerts" + (this.state.assetSelected ? " for the selected asset" : " for all assets") + ", do you wish to continue?"
        }, () => {
            this.showForceReloadConfirmModal();
        });
    }

    showForceReloadConfirmModal() {
        this.setState({
            showForceReloadConfirm: !this.state.showForceReloadConfirm
        })
    }

    async onLongAlertClick() {
        let graphedAlerts = this.state.graphedAlerts;
        let selectedAlerts = this.state.selectedAlerts;
        let alertData = this.state.alertData;
        let dataList = this.state.dataList;
        let longAlertList = this.state.longAlertList;
        const longAlertId = this.state.currentLongAlert.alertId;

        let list = [];
        list = list.concat(this.state.currentLongAlert);

        this.setState({ loading: true, showLongAlertConfirm: false }, async () => {
            await AlertService.getalertlistdata(this.state.currentAsset.assetId, false, list)
                .then(response => {
                    if (this._isMounted) {
                        let longData = response.data;
                        longData[longAlertId].color = alertData[longAlertId].color;
                        alertData[longAlertId] = longData[longAlertId];

                        let series = longData[longAlertId];
                        longAlertList = longAlertList.concat(this.state.currentLongAlert);
                        graphedAlerts = graphedAlerts.concat(this.state.currentLongAlert);
                        selectedAlerts = selectedAlerts.concat(this.state.currentLongAlert);
                        dataList = dataList.concat(series);

                        let seriesRuns = series.series; //breakAlertData(series.data, series.offHigh, series.fanOnlyLow, series.fanOnlyHigh, series.fanOnly);
                        series.series = seriesRuns;

                        let seriesMax = 0;
                        let currMax = 0;
                        if (series !== undefined)
                            for (var i = 0; i < seriesRuns.length; i++) {
                                let thisMax = seriesRuns[i].length;
                                if (thisMax > seriesMax)
                                    seriesMax = thisMax;

                                const seriesMaxVal = this.getSeriesMax(seriesRuns[i]);//Math.max(...seriesRuns[i].map(point => point.value));
                                if (seriesMaxVal > currMax) {
                                    currMax = seriesMaxVal;
                                }
                            }
                        let dataMax = this.state.dataMax;
                        let rawMax = this.state.rawMax;
                        let currentMax = this.state.currentMax;
                        if (seriesMax > dataMax)
                            dataMax = seriesMax;
                        if (series.dataMax > rawMax)
                            rawMax = series.dataMax;
                        if (currMax > currentMax)
                            currentMax = currMax;

                        this.setState({
                            loading: false,
                            longAlertList: longAlertList,
                            graphedAlerts: graphedAlerts,
                            selectedAlerts: selectedAlerts,
                            dataList: dataList,
                            dataMax: dataMax,
                            rawMax: rawMax,
                            currentMax: currentMax,
                            currentLongAlert: {}
                        }, () => {
                            if (this.state.currentRadio === "AirConditioner" && this.state.dataList.length) {
                                let rlaVal = this.state.dataList[0].rla;
                                let rlaMin = rlaVal - 5;
                                if (currentMax >= rlaMin) {
                                    this.setState(prevState => ({
                                        visibility: {
                                            ...prevState.visibility,
                                            rla: true
                                        }
                                    }));
                                }
                            }
                        });
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ loading: false, message: "Failed to load data for selected long alert!" });

                    setTimeout(() => {
                        this.setState({
                            message: ""
                        });
                    }, 3000);
                });
        })
    }

    openLongAlertConfirmModal() {
        this.setState({
            modalTitle: "Long Alert Warning",
            modalMessage: `You are about to select an alert with a long duration (12+ hours). Alerts this long tend to take more time to load the data, do you wish to continue?`
        }, () => {
                this.showLongAlertConfirmModal();
        });
    }

    showLongAlertConfirmModal() {
        this.setState({
            showLongAlertConfirm: !this.state.showLongAlertConfirm
        });
    }

    async onEscalateClick() {
        Event("Escalate clicked", "User clicked to escalate alerts in LifeWhere Triage Alerts", "Escalate clicked");
        this.setState({ loading: true }, async () => {
                await AlertService.triagealerts("ESCALATE", this.state.selectedAlerts)
                    .then(response => {
                        if (response.status === 200)
                            this.setState({
                                //message: "Successfully escalated all selected alerts!",
                                //loading: false, 
                                showEscalateConfirm: false
                            }, () => {
                                let session = this.state.triageSession;
                                let sessionAlerts = this.state.triagedAlertsList;//session.triagedAlerts;
                                for (var i = 0; i < this.state.selectedAlerts.length; i++) {
                                    let thisAlert = this.state.selectedAlerts[i];
                                    let tAlert = {
                                        alertId: parseInt(thisAlert.alertId),
                                        //sessionId: parseInt(session.id), 
                                        statusId: 1,
                                        statusName: "Escalated",
                                        alertTypeId: parseInt(thisAlert.typeId),
                                        alertTypeName: thisAlert.type,
                                        assetTypeId: assetType[this.state.currentAsset.assetType],
                                        assetType: this.state.currentAsset.assetType,
                                        assetId: parseInt(this.state.currentAsset.assetId)
                                    };
                                    sessionAlerts = sessionAlerts.concat(tAlert);
                                }
                                session.endDateTimeStr = moment().format();
                                //session.triagedAlerts = sessionAlerts;

                                this.setState({ triageSession: session, triagedAlertsList: sessionAlerts, message: "Successfully escalated all selected alerts and updated Triage Session!", loading: false }, async () => {
                                    this.reloadAssetAlerts(this.state.currentAsset.assetId);
                                    /*await AlertService.publishtriagesession(this.state.triageSession)
                                        .then(response => {
                                            if (response.status === 200)
                                                this.setState({
                                                    message: "Successfully escalated all selected alerts and updated Triage Session!",
                                                    loading: false,
                                                    //showEscalateConfirm: false
                                                }, () => {
                                                    this.reloadAssetAlerts(this.state.currentAsset.assetId);
                                                });
                                            else
                                                this.setState({ message: "Failed to update Triage Session.", loading: false });

                                            setTimeout(() => {
                                                this.setState({
                                                    message: ""
                                                });
                                            }, 3000);
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            this.setState({ message: "Failed to update Triage Session.", loading: false });

                                            setTimeout(() => {
                                                this.setState({
                                                    message: ""
                                                });
                                            }, 3000);
                                        });*/
                                });
                            });
                        else
                            this.setState({ message: "Failed to escalate all selected alerts.", loading: false, showEscalateConfirm: false });

                        setTimeout(() => {
                            this.setState({
                                message: ""
                            });
                        }, 3000);
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({ message: "Failed to escalate all selected alerts.", loading: false, showEscalateConfirm: false });

                        setTimeout(() => {
                            this.setState({
                                message: ""
                            });
                        }, 3000);
                    });
        });
    }

    openEscalateConfirmModal() {
        this.setState({
            modalTitle: "Escalate Alerts",
            modalMessage: `You are about to verify the selected alerts and escalate each to MEDIUM. Would you like to continue?`
        }, () => {
            this.showEscalateConfirmModal();
        });
    }

    showEscalateConfirmModal() {
        this.setState({
            showEscalateConfirm: !this.state.showEscalateConfirm
        });
    }

    async onFalsePositiveClick() {
        Event("False Positive clicked", "User marked alerts false positive in LifeWhere Triage Alerts", "False Positive clicked");
        var newFP = {
            id: this.state.selectedTypeId,
            type: this.state.currentType,
            count: this.state.selectedAlerts.length
        };
        var fpDict=[];
        if (this.state.falsePosAlertsList.length === 0) {
            fpDict = this.state.falsePosAlertsList;
            fpDict = fpDict.concat(newFP);
        }
        else
        {
            fpDict = newFP;
        }

        let session = this.state.triageSession;
        let sessionAlerts = this.state.triagedAlertsList;//session.triagedAlerts;
        for (var i = 0; i < this.state.selectedAlerts.length; i++) {
            let thisAlert = this.state.selectedAlerts[i];
            let tAlert = {
                alertId: parseInt(thisAlert.alertId, 10),
                //sessionId: parseInt(session.id, 10), 
                statusId: 2, 
                statusName: "False Positive",
                alertTypeId: parseInt(thisAlert.typeId, 10),
                alertTypeName: thisAlert.type,
                assetTypeId: assetType[this.state.currentAsset.assetType],
                assetType: this.state.currentAsset.assetType,
                assetId: parseInt(this.state.currentAsset.assetId)
            };
            sessionAlerts = sessionAlerts.concat(tAlert);
        }
        session.endDateTimeStr = moment().format();
        //session.triagedAlerts = sessionAlerts;

        this.setState({
            loading: true,
            falsePosAlertsList: fpDict,
            triageSession: session,
            triagedAlertsList: sessionAlerts
        }, async () => {
                await AlertService.triagealerts("FALSEPOSITIVE", this.state.selectedAlerts)
                    .then(response => {
                        if (response.status === 200)
                            this.setState({
                                showFalsePositiveConfirm: false,
                                message: "Successfully marked selected alerts as false positive and updated Triage Session!",
                                loading: false
                            }, async () => {
                                this.reloadAssetAlerts(this.state.currentAsset.assetId);
                                    /*await AlertService.publishtriagesession(this.state.triageSession)
                                        .then(response => {
                                            if (response.status === 200)
                                                this.setState({ message: "Successfully marked selected alerts as false positive and updated Triage Session!", loading: false }, () => {
                                                    this.reloadAssetAlerts(this.state.currentAsset.assetId);
                                                });
                                            else
                                                this.setState({ message: "Failed to mark all selected alerts as false positive and update Triage Session.", loading: false });

                                            setTimeout(() => {
                                                this.setState({
                                                    message: ""
                                                });
                                            }, 3000);
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            this.setState({ message: "Failed to mark all selected alerts as false positive and update Triage Session.", loading: false });

                                            setTimeout(() => {
                                                this.setState({
                                                    message: ""
                                                });
                                            }, 3000);
                                        });*/
                            });
                        else
                            this.setState({ message: "Failed to mark all selected alerts as false positive.", loading: false, showFalsePositiveConfirm: false });

                        setTimeout(() => {
                            this.setState({
                                message: ""
                            });
                        }, 3000);
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({ message: "Failed to mark all selected alerts as false positive.", loading: false, showFalsePositiveConfirm: false });

                        setTimeout(() => {
                            this.setState({
                                message: ""
                            });
                        }, 3000);
                    });
        });
    }

    openFalsePositiveConfirmModal() {
        this.setState({
            modalTitle: "False Positive Alerts",
            modalMessage: `You are about to CLOSE the selected alerts and mark each as FALSE POSITIVE. Would you like to continue?`
        }, () => {
                this.showFalsePositiveConfirmModal();
        });
    }

    showFalsePositiveConfirmModal() {
        this.setState({
            showFalsePositiveConfirm: !this.state.showFalsePositiveConfirm
        });
    }

    async onCloseInternalClick() {
        Event("Close Internal clicked", "User selected to close alerts internally in LifeWhere Triage Alerts", "Close Internal clicked");
        this.setState({ loading: true }, async () => {
            await AlertService.triagealerts("CLOSEINTERNAL", this.state.selectedAlerts)
                .then(response => {
                    if (response.status === 200)
                        this.setState({ message: "Successfully closed selected alerts!", loading: false, showCloseInternalConfirm: false }, () => {
                            this.reloadAssetAlerts(this.state.currentAsset.assetId);
                        });
                    else
                        this.setState({ message: "Failed to close all selected alerts.", loading: false, showCloseInternalConfirm: false });

                    setTimeout(() => {
                        this.setState({
                            message: ""
                        });
                    }, 3000);
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ message: "Failed to close all selected alerts.", loading: false, showCloseInternalConfirm: false });

                    setTimeout(() => {
                        this.setState({
                            message: ""
                        });
                    }, 3000);
                })
        });
    }

    openCloseInternalConfirmModal() {
        this.setState({
            modalTitle: "Close Internal Alerts",
            modalMessage: `You are about to CLOSE the selected alerts. Would you like to continue?`
        }, () => {
            this.showCloseInternalConfirmModal();
        });
    }

    showCloseInternalConfirmModal() {
        this.setState({
            showCloseInternalConfirm: !this.state.showCloseInternalConfirm
        });
    }

    async onAddTagsClick(tagIds) {
        Event("Tags Added to alerts", "User added tags to alerts in LifeWhere Triage Alerts", "Tags added to alerts");
        let alerts = this.state.selectedAlerts;

        for (var i = 0; i < alerts.length; i++) {
            let alert = alerts[i];
            alert.tagIds = tagIds;
            alerts[i] = alert;
        }

        this.setState({ selectedAlerts: alerts, loading: true }, async () => {
            await AlertService.triagealerts("ADDTAGS", this.state.selectedAlerts)
                .then(response => {
                    if (response.status === 200)
                        this.setState({ message: "Successfully added tags to all selected alerts!", loading: false, showAddTags: false }, () => {
                            this.reloadAssetAlerts(this.state.currentAsset.assetId);
                        });
                    else 
                        this.setState({ message: "Failed to add tags to all selected alerts.", loading: false, showAddTags: false });
                    
                    setTimeout(() => {
                        this.setState({
                            message: ""
                        });
                    }, 3000);
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ message: "Failed to add tags to all selected alerts.", loading: false, showAddTags: false });

                    setTimeout(() => {
                        this.setState({
                            message: ""
                        });
                    }, 3000);
                });
        });
    }

    async openAddTagsModal() {
        await AlertService.getalerttags()
            .then(response => {
                if (this._isMounted)
                    this.setState({ alertTags: response.data }, () => {
                        this.showAddTagsModal();
                    });
            })
            .catch(e => {
                console.log(e);
            });
    }

    showAddTagsModal() {
        this.setState({
            showAddTags: !this.state.showAddTags
        }, () => {
            if (this.state.showAddTags)
                ModalView('addTags');
        });
    }

    async onChangeAlertTypeClick(typeId, escalate) {
        Event("Alert Type Changed", "Alert type changed in LifeWhere Triage Alerts", "Alert type changed");
        let alerts = this.state.selectedAlerts;
    
        for (var i = 0; i < alerts.length; i++) {
            let alert = alerts[i];
            alert.typeId = typeId;
            alerts[i] = alert;
        }
        this.setState({ selectedAlerts: alerts, loading: true }, async () => {
            await AlertService.triagealerts("CHANGEALERTTYPE", this.state.selectedAlerts)
                .then(response => {
                    if (response.status === 200)
                        this.setState({ message: "Successfully changed the alert type of all selected alerts!", loading: false, showChangeAlertType: false }, async () => {
                            if (escalate === true) {
                                this.onEscalateClick();
                            }
                            else {
                                this.reloadAssetAlerts(this.state.currentAsset.assetId);
                            }
                        });
                    else 
                        this.setState({ message: "Failed to change the alert type of all selected alerts.", loading: false, showChangeAlertType: false });
                    
                    setTimeout(() => {
                        this.setState({
                            message: ""
                        });
                    }, 3000);
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ message: "Failed to change the alert type of all selected alerts.", loading: false, showChangeAlertType: false });

                    setTimeout(() => {
                        this.setState({
                            message: ""
                        });
                    }, 3000);
                });
        });
    }

    async openChangeAlertTypeModal() {
        await AlertService.getalerttypes()
            .then(response => {
                if (this._isMounted)
                    this.setState({ alertTypes: response.data }, () => {
                        this.showChangeAlertTypeModal();
                    });
            })
            .catch(e => {
                console.log(e);
            });
    }

    showChangeAlertTypeModal() {
        this.setState({
            showChangeAlertType: !this.state.showChangeAlertType
        }, () => {
            if (this.state.showChangeAlertType)
                ModalView('changeAlertType');
        });
    }

    onUnitEKGClick() {
        Event("View Unit EKG Clicked", "User clicked to view LifeWHere Triage Alerts asset in TwinThread", "View Unit EKG clicked");
        window.open(this.state.unitEKGUrl, "_blank");
        this.setState({ showUnitEKGConfirm: false });
    }

    async openUnitEKGConfirmModal() {
        await AlertService.getassetcontenttab(this.state.currentAsset.assetId, "Unit_EKG")
            .then(response => {
                if (this._isMounted)
                    this.setState({
                        modalTitle: "View Unit EKG",
                        modalMessage: `You are about to be redirected to TwinThread. Would you like to continue?`,
                        unitEKGUrl: "https://app.lifewhere.com/twins/detail/" + this.state.currentAsset.assetId + "/" + response.data
                    }, () => {
                        this.showUnitEKGConfirmModal();
                    });
            })
            .catch(e => {
                console.log(e);
            });
    }

    showUnitEKGConfirmModal() {
        this.setState({
            showUnitEKGConfirm: !this.state.showUnitEKGConfirm
        });
    }

    onUnitAlertClick() {
        Event("View Unit Alert clicked", "User clicked to view alert in TwinThread", "View Unit Alert clicked");
        window.open(this.state.unitAlertUrl, "_blank");
        this.setState({ showUnitAlertConfirm: false });
    }

    openUnitAlertConfirmModal = (id) => {
        const alertId = id;
        
        if (this._isMounted)
            this.setState({
                modalTitle: "View Alert in TwinThread",
                modalMessage: `You are about to open TwinThread in a new tab. Would you like to continue?`,
                unitAlertUrl: "https://app.lifewhere.com/alerts/detail/" + alertId + "/overview"
            }, () => {
                this.showUnitAlertConfirmModal();
            });
    }

    showUnitAlertConfirmModal() {
        this.setState({
            showUnitAlertConfirm: !this.state.showUnitAlertConfirm
        });
    }

/* END GRAPH BUTTON FUNCTIONS */

    getStartDate(startTime) {
        var date = moment(startTime).format();
        return (date);
    }

    getAddress() {
        var addy = "";

        if (this.state.currentCustomer.address !== "" && this.state.currentCustomer.address !== null) {
            addy = this.state.currentCustomer.address;

            if (this.state.currentCustomer.city !== "" && this.state.currentCustomer.city !== null) {
                addy += ", " + this.state.currentCustomer.city;
            }

            if (this.state.currentCustomer.state !== "" && this.state.currentCustomer.state !== null) {
                addy += ", " + this.state.currentCustomer.state;

                if (this.state.currentCustomer.zip !== "" && this.state.currentCustomer.zip !== null)
                    addy += " " + this.state.currentCustomer.zip;
            }
        }
        return (addy);
    }

    getAlertTypeCount(alert) {
        const allAlerts = this.state.assetAlertList; 
        const alerts = allAlerts.filter(a => a.type === alert);
        return alerts.length;
    }

    getEscalatedAlertTypeCount(alert) {
        const allAlerts = this.state.escalatedAlertsObj.alertsByAssetDict[this.state.currentAsset.assetId];
        const alerts = allAlerts.filter(a => a.type === alert);
        return alerts.length;
    }

    getGraphButton(alert, index) {
        let graphButtonContent = <VisibilityIcon style={{ color: "#0033a0", width: '20px', height: '20px' }} />;

        let graphButtonFormat = {
            width: '25px', height: '25px', borderRadius: '.2rem', display: 'flex', justifyContent: 'center'
        };

        const allAlerts = this.state.escalatedAlertsObj.alertsByAssetDict[this.state.currentAsset.assetId];
        const alerts = allAlerts.filter(a => a.type === alert);

        let graphButton = <ViewGraphButton currentAlert={alerts[alerts.length - 1]} currentAsset={this.state.currentAssetInfo}
            show={this.state.showModals[index]} showGraphModal={this.showGraphModal} _isMounted={this._isMounted}
            buttonContent={graphButtonContent} buttonFormat={graphButtonFormat} buttonId={index} />;
        return graphButton;
    }

    getRunCount(alertId) {
        const data = this.state.alertData[alertId];
        let count = 0;
        if (data !== undefined && data.series !== undefined && data.series !== null && data.series.length)
            count = data.series.length;

        return count;
    }

    renderGraph() {
        let buttons =
            <div style={{ marginTop: '25px' }}>
                <div><button className="secondary-btn btn-small" onClick={this.openEscalateConfirmModal} style={{ width: '140px', marginBottom: '2px' }} disabled={!this.state.selectedAlerts.length}>Escalate</button></div>
                <div><button className="secondary-btn btn-small" onClick={this.openFalsePositiveConfirmModal} style={{ width: '140px', marginBottom: '2px' }} disabled={!this.state.selectedAlerts.length}>False Positive</button></div>
                <div><button className="secondary-btn btn-small" onClick={this.openCloseInternalConfirmModal} style={{ width: '140px', marginBottom: '2px' }} disabled={!this.state.selectedAlerts.length}>Close Internal</button></div>
                <div><button className="secondary-btn btn-small" onClick={this.openAddTagsModal} style={{ width: '140px', marginBottom: '2px' }} disabled={!this.state.selectedAlerts.length}>Add Tags</button></div>
                <div><button className="secondary-btn btn-small" onClick={this.openChangeAlertTypeModal} style={{ width: '140px', marginBottom: '2px' }} disabled={!this.state.selectedAlerts.length}>Change Alert Type</button></div>
                <div><button className="secondary-btn btn-small" onClick={this.openUnitEKGConfirmModal} style={{ width: '140px', marginBottom: '2px' }}>View Unit EKG</button></div>
                {/*<div><button className="secondary-btn btn-small" onClick={this.analyzeIgnition} style={{ width: '140px', marginBottom: '2px' }}>Analyze Ignition</button></div>*/}
                {/*<div><button className="secondary-btn btn-small" onClick={this.analyzeShutdown} style={{ width: '140px', marginBottom: '2px' }}>Analyze Shutdown</button></div>*/}
            </div>;

        let acOptions = this.state.currentRadio === "AirConditioner" ?
            <div className="grey-container" style={{ marginLeft: '15px', marginTop: '20px', width: '150px' }}>
                <h6 className="sectionTitle">Graph Display Options</h6>
                <div className="radio-container" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left' }}>

                    <div>
                        <input type="checkbox" value="rla" checked={this.state.visibility.rla} id="gdo" onChange={this.handleCheckbox} />
                                    &nbsp; RLA &nbsp;&nbsp;
                            </div>
                    <div>
                        <input type="checkbox" value="lra" checked={this.state.visibility.lra} id="gdo" onChange={this.handleCheckbox} />
                                    &nbsp; LRA &nbsp;&nbsp;
                            </div>
                    <div>
                        <input type="checkbox" value="fla" checked={this.state.visibility.fla} id="gdo" onChange={this.handleCheckbox} />
                                    &nbsp; FLA &nbsp;&nbsp;
                            </div>

                </div>
            </div>
            : null;

        return (
            <div className="row no-gutters">
                <div className="col-sm-12 col-md-10">

                    <HighchartGraph graphType={"ALERT"} chartData={this.state.chartDataList} key={`${this.state.selectedTypeId}-${this.state.chartDataList.length}-${this.state.dataList.length}-${this.state.visibility}`} isAirConditioner={this.state.currentRadio === "AirConditioner" ? true : false}
                        currentMax={this.state.currentMax} dataMax={this.state.dataMax} rawMax={this.state.rawMax} rawData={this.state.rawDataList} last150Data={this.state.last150DataList} showRLA={this.state.visibility.rla} rlaValue={this.state.dataList.length ? this.state.dataList[0].rla : null}
                        showLRA={this.state.visibility.lra} lraValue={this.state.dataList.length ? this.state.dataList[0].lra : null} showFLA={this.state.visibility.fla} flaValue={this.state.dataList.length ? this.state.dataList[0].fla : null} alertList={this.state.graphedAlerts} 
                        assetId={this.state.currentAsset.assetId} setLoading={this.setLoading} hasTempAxis={false}
                    /> 
                       
                </div>
                <div className="col-sm-4 col-md-2">
                        <div style={{ marginLeft: '20px' }}>
                            {buttons}
                    </div>
                    {acOptions}
                   
                </div>
            </div>
        );
    }

    showViewAllButton() {
        let showButton = false;

        if (this.state.currentAsset.alertTypes !== undefined && this.state.currentAsset.alertTypes.length) {
            let totalAlerts = 0;

            this.state.currentAsset.alertTypes.forEach(a => {
                totalAlerts += this.getAlertTypeCount(a);
            });

            if (totalAlerts <= 20)
                showButton = true;
        }

        return showButton;
    }

    renderAssetContent() {
        const { classes } = this.props;

        let alertLoader = this.state.alertTableLoading ?
            <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : null;

        let customerInfoBtn = this.state.showCustomerInfo ?
            <button className="tertiary-btn" onClick={this.onCustomerInfoClick} style={{ margin: '5px 10px' }}>- Hide Customer Info</button>
            : <button className="tertiary-btn" onClick={this.onCustomerInfoClick} style={{ margin: '5px 10px' }}>Show Customer Info</button>;

        let customerInfoContent = this.state.showCustomerInfo ?
            this.state.customerLoading ?
                <div className='centered'><Spinner animation="border" variant="dark" /></div>
                : <div style={{ marginLeft: '15px', marginRight: '15px' }}>
                    <small>
                        <div className="detailTitle">Customer Information</div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Customer:</p>
                            </div>
                            <div className="col-8">
                                <p>{this.state.currentCustomer.name}</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Partner:</p>
                            </div>
                            <div className="col-8">
                                <p>{this.state.currentPartnerName}</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Phone:</p>
                            </div>
                            <div className="col-8">
                                <p>{this.state.currentCustomer.phone}</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Email:</p>
                            </div>
                            <div className="col-8">
                                <p>{this.state.currentCustomer.email}</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Address:</p>
                            </div>
                            <div className="col-8">
                                {this.getAddress()}
                            </div>
                        </div>
                    </small>
                </div>
            : null;

        let assetInfoBtn = this.state.showAssetInfo ?
            <button className="tertiary-btn" onClick={this.onAssetInfoClick} style={{ margin: '5px 0px' }}>- Hide Asset Info</button>
            : <button className="tertiary-btn" onClick={this.onAssetInfoClick} style={{ margin: '5px 0px' }}>Show Asset Info</button>;

        let assetInfoContent = this.state.showAssetInfo ?
            this.state.assetLoading ?
                <div className='centered'><Spinner animation="border" variant="dark" /></div>
                : <div style={{ marginLeft: '15px', marginRight: '15px' }}>
                    <small>
                        <div className="detailTitle">Asset Information</div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Make:</p>
                            </div>
                            <div className="col-8">
                                <p>{this.state.currentAssetInfo.make}</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Model:</p>
                            </div>
                            <div className="col-8">
                                <p>{this.state.currentAssetInfo.model}</p>
                            </div>
                        </div>
                    </small>
                </div>
            : null;

        let retrainBtn = this.state.currentAssetInfo.hasRetrain ?
            null
            : <button className="btn-small secondary-btn" style={{ color: 'red', border: '1px solid red', marginLeft: '10px' }} onClick={this.openRetrainConfirmModal}>Retrain Asset</button>;
        let escalatedAlertsBtn = (!Object.keys(this.state.escalatedAlertsObj).length|| !this.state.escalatedAlertsObj.triageAlertsAssets.length) ? null : this.state.showEscalatedAlerts ?
            <button className="tertiary-btn" onClick={this.onEscalatedAlertsClick}>- Hide Escalated Alerts</button>
            : <button className="tertiary-btn" onClick={this.onEscalatedAlertsClick}>Show Escalated Alerts</button>;

        let escalatedAlertsContent = this.state.showEscalatedAlerts ?
            this.state.escalatedAlertsLoading ?
                <div className='centered'><Spinner animation="border" variant="dark" /></div>
                : <div className="table-container">
                    <div style={{ marginBottom: '0px', overflow: "auto" }}>
                        <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                            <thead>
                                <tr>
                                    <th>Alert Type</th>
                                    <th>Alert Count</th>
                                    <th>Show Graph</th>
                                </tr>
                            </thead>
                            
                            <tbody>
                                {this.state.escalatedAlertsObj.triageAlertsAssets !== undefined && this.state.escalatedAlertsObj.triageAlertsAssets.length ?
                                    this.state.escalatedAlertsObj.triageAlertsAssets[0].alertTypes.map((alert, index) =>
                                        [<tr key={`${alert}-${index}`} data={index}>
                                            <td>{alert}</td>
                                            <td>{this.getEscalatedAlertTypeCount(alert)}</td>
                                            <td style={{ display: 'flex', justifyContent: 'center' }}>
                                                {this.getGraphButton(alert, index)}
                                            </td>
                                        </tr>
                                    ])
                                    : <tr><td>No Alert Types for this Asset!</td></tr>
                                }
                            </tbody>

                        </table>
                    </div>
                </div>
            : null;

        let viewAllBtn = this.showViewAllButton() ?
            <div>
                <button className={this.state.showAllAlerts ? "btn-small primary-btn" : "btn-small secondary-btn"} style={{ width: 'calc(100% - 30px)', marginLeft: '15px', marginBottom: '5px' }} onClick={this.onViewAllAlertsClick}>View All Alerts</button>
            </div>
            : null;

        let tableContent = this.state.typeAlertList.length && this.state.alertTypeSelected ?
            <tbody>
                {this.state.typeAlertList.map((alert, index) =>
                    <tr key={`${index}-${alert.alertId}`} data={index}>
                        <td>{alert.alertId} {parseInt(alert.duration, 10) >= 720 ? " (long)" : null }</td>
                        <td>{this.getStartDate(alert.startTime)}</td>
                        <td>{this.getStartDate(alert.endTime)}</td>
                        <td>{this.getRunCount(alert.alertId)}</td>
                        <td>
                            <input type="checkbox" value={alert.alertId} id={alert.alertId} checked={this.state.selectedAlerts.some(a => parseInt(a.alertId) === parseInt(alert.alertId))} onChange={this.handleAlertCheck} />
                        </td>
                        <td>
                            {(parseInt(alert.duration, 10) < 720 || this.state.longAlertList.some(a => parseInt(a.alertId) === parseInt(alert.alertId))) ?
                                <input type="checkbox" value={alert.alertId} id={`${alert.alertId}-graph`} checked={this.state.graphedAlerts.some(a => parseInt(a.alertId) === parseInt(alert.alertId))} onChange={this.handleGraphAlertCheck} />
                                : <input type="checkbox" value={alert.alertId} id={alert.alertId} checked={this.state.graphedAlerts.some(a => parseInt(a.alertId) === parseInt(alert.alertId))} onChange={this.handleLongAlertCheck} />
                            } 
                        </td>
                        <td>
                            <div style={{ display: 'flex' }}>
                                    <div style={{ height: '13px', width: '13px', border: '1px solid grey', backgroundColor: this.state.alertData[alert.alertId] !== undefined ? this.state.alertData[alert.alertId].color : 'white' }} onClick={() => this.handleColorClick(alert.alertId)} />
                                    {this.state.showColorPicker && this.state.selectedColor === alert.alertId ?
                                        <div style={{ position: 'absolute', zIndex: '2', right: '5px' }}>
                                            <div onClick={this.closeColorPicker} style={{ position: 'fixed', top: '0px', bottom: '0px', left: '0px', right: '0px' }}/>
                                            <SketchPicker color={this.state.alertData[alert.alertId] !== undefined ? this.state.alertData[alert.alertId].color : 'white'}  onChange={this.handleColorChange} />
                                        </div>
                                        : null
                                    }
                                    <IconTooltip title="Show in TwinThread" enterDelay={200} leaveDelay={200} style={{ marginTop: '-5px', paddingTop: '-5px', backgroundColor: '#f7f9fa' }}>
                                        <IconButton key={`${index}-${alert.alertId}`} onClick={() => this.openUnitAlertConfirmModal(alert.alertId)} style={{ padding: '0px', marginLeft: '5px' }}>
                                            <VisibilityIcon style={{ color: "#0033a0", width: '13px', height: '13px' }} />
                                        </IconButton>
                                    </IconTooltip>
                            </div>
                        </td>
                    </tr>
                )}
            </tbody>
            : <tbody>
                <tr><td>{this.state.alertTableLoading || this.state.loading ? "Loading Asset Alerts of Selected Type..." : "No Alerts of this Type"}</td></tr>
            </tbody>;

        let alertTable = this.state.alertTypeSelected ?
            <div style={{ maxHeight: '330px', marginBottom: '15px', overflow: "auto" }}>
                <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Alert Id</th>
                            <th>Start Time</th>
                            <th>End Time </th>
                            <th>Runs</th>
                            <th>Select <input type="checkbox" value={this.state.allSelected} checked={this.state.allSelected} id='selectAll' onChange={this.onSelectAll} style={{ marginLeft: '5px' }} /></th>
                            <th>Graph <input type="checkbox" value={this.state.allGraphed} checked={this.state.allGraphed} id='graphAll' onChange={this.onGraphAll} style={{ marginLeft: '5px' }} /></th>
                            <th style={{ width: '25px' }}></th>
                        </tr>
                    </thead>
                    {tableContent}
                </table>
            </div>
            : <div>{null}</div>;

        return (
            <div style={{ marginTop: '10px' }}>

                <div className="row no-gutters alerts-tab-wrapper" style={{ margin: '20px 30px', border: '1px solid #ebeef0', borderRadius: '.1rem', boxShadow: 'inset 0 -1px 0 rgba(100,121,143,0.122)' }}>
                    <div className="col-2"></div>
                    <div className="col-4" style={{ paddingTop: '15px', textAlign: 'center' }}>
                        <p>Assets in Queue: &nbsp;<b>{this.state.queueCounts[this.state.currentRadio].numAssets}</b></p>
                    </div>
                    <div className="col-4" style={{ paddingTop: '15px', textAlign: 'center' }}>
                        <p>Alerts in Queue: &nbsp;<b>{this.state.queueCounts[this.state.currentRadio].numAlerts}</b></p>
                    </div>
                    <div className="col-2"></div>
                </div>

                <div className="row no-gutters" style={{ display: 'flex' }}>
                    <div className="col-sm-12 col-md-4">

                        <div className="grey-container" style={{ minHeight: 'calc(100% - 10px)' }}>
                            <h5 className="sectionTitle">
                                <button className="tertiary-btn" onClick={this.onBackClick} style={{ margin: '0px' }}>{"<"}</button>
                                Asset Id: {this.state.currentAsset.assetId}
                                <IconTooltip title="Force Reload Asset Alerts" enterDelay={200} leaveDelay={200} style={{ marginTop: '-5px', paddingTop: '-5px', backgroundColor: '#f7f9fa' }}>
                                    <IconButton onClick={() => this.openForceReloadConfirmModal()} style={{ padding: '0px', marginLeft: '8px', marginTop: '-5px' }}>
                                        <RefreshIcon style={{ color: "#0033a0", width: '20px', height: '20px' }} />
                                    </IconButton>
                                </IconTooltip>
                            </h5>
                            <div className="detailTitle" style={{ marginLeft: '15px' }}>
                                Mac: {this.state.currentAsset.deviceId}
                            </div>
                            <div className="table-container">

                                    <div style={{ marginBottom: '0px', overflow: "auto" }}>
                                        <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                                            <thead>
                                                <tr>
                                                    <th>Alert Type</th>
                                                    <th>Alert Count</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                            {this.state.currentAsset.alertTypes !== undefined && this.state.currentAsset.alertTypes.length ?
                                                this.state.currentAsset.alertTypes.map((alert, index) =>
                                                    <tr key={`${alert}-${index}`} data={index} className={this.state.typeSelected === index ? "tableSelected" : ""} onClick={() => { this.onAlertTypeClick(index) }}>
                                                        <td>{alert}</td>
                                                        <td>{this.getAlertTypeCount(alert)}</td>
                                                    </tr>
                                                )
                                                : <tr><td>No Alert Types for this Asset!</td></tr>
                                            }
                                            </tbody>

                                        </table>
                                    </div>
                            </div>

                            {viewAllBtn}

                            <div>
                                {customerInfoContent}
                                {assetInfoContent}
                                {escalatedAlertsContent}
                                {customerInfoBtn}
                                {assetInfoBtn}
                                {escalatedAlertsBtn}
                            </div>
                            <div className="lastItem">
                                {retrainBtn}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-8">

                        <div className="white-container" style={{ minHeight: '100%' }}>
                            <h5 className="sectionTitle">{this.state.currentType === "" ? "Selected Alert Type" : this.state.currentType}</h5>
                            {this.state.alertTypeSelected && this.state.typeAlertList !== undefined && this.state.typeAlertList.length ?
                                <div className="detailTitle" style={{ marginLeft: '15px' }}>
                                    {"TypeId: " + this.state.typeAlertList[0].typeId + ", Description: " + this.state.typeAlertList[0].description}
                                </div>
                                : null}
                            <div className="table-container" style={{ maxHeight: '330px' }}>
                                {alertTable}
                            </div>
                            {alertLoader}
                        </div>
                    </div>
                </div>

                <div>
                    {this.state.alertTypeSelected ?
                        <div>
                            <br />
                            {this.renderGraph()}
                        </div>
                        : <div className="centered">
                            <br />
                            Select an Alert Type to generate graph
                            </div>
                    }
                    <p>{this.state.message}</p>
                </div>
            </div>
        )
    }

    renderAlertContent() {
        const { classes } = this.props;

        let alertLoader = this.state.alertTableLoading ?
            <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : null;

        let tableContent = this.state.assetList !== undefined && this.state.assetList.length ?
            <tbody>
                {this.state.assetList.map((asset, index) =>
                    <tr key={asset.assetId} data={index} className={this.state.isSelected === index ? "tableSelected" : ""} onClick={() => { this.onAlertAssetClick(index) }}>
                        <td>{asset.assetId}</td>
                        <td>{asset.deviceId}</td>
                        <td>{asset.alertCount}</td>
                        <td>{asset.alertTypesStr}</td>
                    </tr>
                )}
            </tbody>
            : <tbody>
                <tr><td>{this.state.alertTableLoading || this.state.loading ? "Loading Untriaged Alerts by Asset..." : "No Untriaged Alerts"}</td></tr>
            </tbody>;

        return (
            <div className="white-container" style={{ minHeight: '100%' }}>
                <h5 className="sectionTitle">
                    Alerts by Asset
                    <IconTooltip title="Force Reload Alerts" enterDelay={200} leaveDelay={200} style={{ marginTop: '-5px', paddingTop: '-5px', backgroundColor: '#f7f9fa' }}>
                        <IconButton onClick={() => this.openForceReloadConfirmModal()} style={{ padding: '0px', marginLeft: '8px', marginTop: '-5px' }}>
                            <RefreshIcon style={{ color: "#0033a0", width: '20px', height: '20px' }} />
                        </IconButton>
                    </IconTooltip>
                </h5>
                <div className="radio-container" onChange={e => this.changeRadio(e)} >
                    <span>
                        <input type="radio" value="Furnace" defaultChecked name="assetType" />
                            &nbsp; Furnace &nbsp;&nbsp;
                        <input type="radio" value="AirConditioner" name="assetType" />
                            &nbsp; Air Conditioner &nbsp;&nbsp;
                        <input type="radio" value="Other" name="assetType" />
                            &nbsp; Other &nbsp;&nbsp;
                    </span>
                </div>
                <div className="table-container">

                    <div style={{ marginBottom: '15px', overflow: "auto" }}>
                        <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                            <thead>
                                <tr>
                                    <th>Asset Id</th>
                                    <th>Device Id</th>
                                    <th>Alert Count</th>
                                    <th>Alert Types</th>
                                </tr>
                            </thead>
                            {tableContent}
                        </table>
                        {alertLoader}
                    </div>
                </div>
            </div>
        );
    }

    renderInitContent() {
        let sessionButton = !this.state.startSessionLoading ?
            <button className="primary-btn btn-large" style={{ width: '175px' }} onClick={ this.handleStartMenu }>Start Triage Session</button>
            : <button className="primary-btn btn-large" style={{ width: '175px', backgroundColor: 'lightGrey', color: 'grey', borderColor: 'grey' }} onClick={ this.handleStartMenu } disabled={true}>Loading Session...</button>

        return (
            <div>
                <div className="alerts-tab-wrapper" style={{ margin: '20px 30px', backgroundColor: "#f7f9fa", padding: '10px 0' }}>
                    <div /*onChange={e => this.changeRadio(e)}*/ style={{ paddingLeft: '40px', margin: '5px' }}>
                        <div style={{ height: '24px', display: 'flex', alignItems: 'center' }}>
                            <input type="radio" value="Furnace" name="assetType" onChange={e => this.changeRadio(e)} checked={this.state.currentRadio === "Furnace"} /*defaultChecked*/ />
                                    &nbsp; Furnace &nbsp;&nbsp;
                            <input type="radio" value="AirConditioner" name="assetType" onChange={e => this.changeRadio(e)} checked={this.state.currentRadio === "AirConditioner"} />
                                &nbsp; Air Conditioner &nbsp;&nbsp;
                            <input type="radio" value="Other" name="assetType" onChange={e => this.changeRadio(e)} checked={this.state.currentRadio === "Other"} />
                                &nbsp; Other &nbsp;&nbsp;
                        </div>
                    </div >
                </div>

                <div className="row no-gutters alerts-tab-wrapper" style={{ margin: '20px 30px', border: '1px solid #ebeef0', borderRadius: '.1rem', boxShadow: 'inset 0 -1px 0 rgba(100,121,143,0.122)' }}>
                    <div className="col-2"></div>
                    <div className="col-4" style={{ paddingTop: '15px', textAlign: 'center' }}>
                        <p>Assets in Queue: &nbsp;<b>{this.state.queueCounts[this.state.currentRadio].numAssets}</b></p>
                    </div>
                    <div className="col-4" style={{ paddingTop: '15px', textAlign: 'center' }}>
                        <p>Alerts in Queue: &nbsp;<b>{this.state.queueCounts[this.state.currentRadio].numAlerts}</b></p>
                    </div>
                    <div className="col-2"></div>
                </div>

                <div className="row no-gutters" style={{ margin: '20px 30px' }}>
                    <div className="col-2"></div>
                    <div className="col-8" style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
                        {sessionButton}
                        <Menu
                            id="menu-start"
                            anchorEl={this.state.anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={this.state.openStartMenu}
                            onClose={this.handleCloseStartMenu}
                        >
                            <MenuItem onClick={() => { this.onStartSessionClick(true) }}>Asset with Most Alerts</MenuItem>
                            <MenuItem onClick={() => { this.onStartSessionClick(false) }}>Asset with Oldest Alerts</MenuItem>
                        </Menu>
                    </div>
                    <div className="col-2"></div>
                </div>

            </div>
        )
    }

    render() {
        const { classes } = this.props;

        let loader = this.state.loading ?
            <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : null;

        let content = this.state.assetSelected ? this.renderAssetContent() : this.renderInitContent(); //this.renderAlertContent();

        let skipAssetBtn = this.state.assetSelected ?
            <button className="secondary-btn btn-large" style={{ width: 150 + 'px' }} onClick={this.onSkipAssetClick}>Skip Asset</button>
            : null;

        return (
            <div className={classes.root}>
                <div className="infoPage">
                    <div className="row no-gutters">
                        <div className="col">
                            <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                                LifeWhere Monitor
                            </h2>
                        </div>
                        <div className="col">
                            <div className="btn-right" style={{ padding: '25px 0px 0px 30px' }}>
                                {skipAssetBtn}
                                <IconTooltip title="Triage Menu" enterDelay={200} leaveDelay={300} style={{ marginTop: '-5px', paddingTop: '-5px', backgroundColor: '#f7f9fa' }}>
                                    <IconButton
                                        aria-label="More options for triage session"
                                        aria-controls="menu-more"
                                        aria-haspopup="true"
                                        onClick={this.handleMoreMenu}
                                        className={classes.menuIcon}
                                        style={{ padding: '5px' }}
                                    >
                                        <MenuIcon style={{ color: "#0033a0", height: '28px', width: '28px' }} />
                                    </IconButton>
                                </IconTooltip>
                                <Menu
                                    id="menu-more"
                                    anchorEl={this.state.anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={this.state.openMoreMenu}
                                    onClose={this.handleCloseMoreMenu}
                                >
                                    <MenuItem onClick={this.openSkipsModal}>Show Skipped Assets</MenuItem>
                                    <MenuItem onClick={this.openCheckoutsModal}>Show Current Checkouts</MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    {content}

                    {loader}
                </div>

                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={this.state.timeout}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    debounce={500}
                />
                <IdleTriageModal onClick={this.onIdleRestart} show={this.state.showIdleModal} />
                <ConfirmationModal show={this.state.showEscalateConfirm} onYes={this.onEscalateClick} onClick={this.showEscalateConfirmModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                <ConfirmationModal show={this.state.showFalsePositiveConfirm} onYes={this.onFalsePositiveClick} onClick={this.showFalsePositiveConfirmModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                <ConfirmationModal show={this.state.showUnitEKGConfirm} onYes={this.onUnitEKGClick} onClick={this.showUnitEKGConfirmModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                <ConfirmationModal show={this.state.showCloseInternalConfirm} onYes={this.onCloseInternalClick} onClick={this.showCloseInternalConfirmModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                <ConfirmationModal show={this.state.showUnitAlertConfirm} onYes={this.onUnitAlertClick} onClick={this.showUnitAlertConfirmModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                <ConfirmationModal show={this.state.showLongAlertConfirm} onYes={this.onLongAlertClick} onClick={this.showLongAlertConfirmModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                <ConfirmationModal show={this.state.showForceReloadConfirm} onYes={this.onForceReloadClick} onClick={this.showForceReloadConfirmModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                
                <AlertTagsModal show={this.state.showAddTags} selectedTags={this.state.selectedTags} alertTags={this.state.alertTags} onAddAlertTags={this.onAddTagsClick} onClick={this.showAddTagsModal} ></AlertTagsModal>
                <AlertTypeModal show={this.state.showChangeAlertType} selectedTypeId={this.state.selectedTypeId} alertTypes={this.state.alertTypes} onChangeAlertType={this.onChangeAlertTypeClick} onClick={this.showChangeAlertTypeModal}></AlertTypeModal>
                <RetrainAssetModal show={this.state.showRetrainConfirm} currentMacId={this.state.currentAsset.deviceId} falsePositiveAlerts={this.state.falsePosAlertsList} onRetrainAsset={this.onRetrainClick} onClick={this.showRetrainConfirmModal}></RetrainAssetModal>

                <TriageMenuModal show={this.state.showSkips} title={"Skipped Assets"} isSkips={true} modalList={this.state.modalList} loading={this.state.moreModalLoading} onClick={this.showSkipsModal}
                    clearedAssets={this.state.clearedSkips} clearedAssetsSuccess={this.state.clearedSkipsSuccess} onClear={this.clearUserSkips} />
                <TriageMenuModal show={this.state.showCheckouts} title={"Current Checkouts"} isSkips={false} modalList={this.state.modalList} loading={this.state.moreModalLoading} onClick={this.showCheckoutsModal}
                    clearedAssets={this.state.clearedCheckouts} clearedAssetsSuccess={this.state.clearedCheckoutsSuccess} onClear={this.clearCheckouts} />

                <Snackbar open={this.state.showMessage} message={this.state.message} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} />
            </div>
        );
    }
}

export default withStyles(styles)(InternalTriageAlerts);