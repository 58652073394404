/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/deviceRequests";
class DeviceRequestsService {

    async getdevicerequests(deviceId) {
        const response = await http.get(`${controller}/DeviceAll/${deviceId}`);
        const data = response;
        return data;
    }

    async getdevicerequestsbetween(deviceId, start, end) {
        const response = await http.get(`${controller}/DeviceAllBetween/${deviceId}?startDate=${start}&endDate=${end}`);
        const data = response;
        return data;
    }
    async getdevicerequest(deviceId) {
        const response = await http.get(`${controller}/Device/${deviceId}`);
        const data = response;
        return data;
    }
};

export default new DeviceRequestsService();